import { useState, useEffect, useMemo } from 'preact/hooks';
import { setParams } from '../../utils/setParams';
import { getParams } from '../../utils/getParams';
import { testIsDigit } from '../../utils/regex';
import {
  firstBrand,
  firstModel,
  firstSize,
  firstModelYear,
  formData,
} from '../../data';

import { useSupportedModels } from '../useSupportedModels/useSupportedModels';

function useFields() {
  const { loading, brands: brand } = useSupportedModels();
  const [fields, setFields] = useState({
    brand: firstBrand,
    model: firstModel,
    modelYear: firstModelYear,
    size: firstSize,
    zipCodes: [],
  });

  const actions = useMemo(
    () => ({
      zipCodes: ({ value }) => [
        value
          .split(',')
          .filter((value) => testIsDigit(value) && value.length === 5)
          .slice(0, 9),
      ],
      model: ({ value, brandValue }) => {
        const { model } = brand.find((b) => b.value === brandValue);

        return model.filter((m) => m.value === value);
      },
      other: ({ value, key }) => {
        return [formData[key].find((obj) => obj.value === value)];
      },
    }),
    [brand]
  );

  useEffect(() => {
    if (!loading) {
      const initialValues = getParams(location);
      const { brand: brandValue } = initialValues;

      for (const [key, value] of Object.entries(initialValues)) {
        if (value) {
          const keyReducerFunction = actions[key] || actions.other;
          const [valueFromParam] = keyReducerFunction({
            value,
            key,
            brandValue,
          });
          setFields((prevFields) => ({
            ...prevFields,
            [key]: valueFromParam,
          }));
        }
      }
    }
  }, [actions, loading]);

  useEffect(() => {
    if (!loading) {
      setParams({
        brand: fields.brand.value,
        model: fields.model.value,
        modelYear: fields.modelYear.value,
        size: fields.size.value,
        zipCodes: fields.zipCodes.join(','),
      });
    }
  }, [fields, loading]);

  return [fields, setFields, { brand, loading }];
}

export { useFields };
