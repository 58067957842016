import { Fragment, h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { gsap } from 'gsap';
import { cx } from 'utils/cx';
import { AdInfoList } from '../AdInfoList';
import styles from './AdInfo.scss';
import constants from '../../constants';

const AdInfo = ({ adData, showAdInfo, setShowAdsInfo, index, gaEvent }) => {
  const { defaultMessage } = constants;
  const infoRef = useRef(null);

  const infoPanelClasses = cx(styles, [
    'ad-info__button',
    showAdInfo && 'ad-info__button--open',
  ]);

  const isOfferDefault =
    !adData.source || adData.source.offerType === 'default';

  useEffect(() => {
    if (infoRef.current) {
      const infoHeight = getComputedStyle(infoRef.current.childNodes[0]).height;

      gsap.to(infoRef.current, {
        duration: 0.6,
        height: showAdInfo !== false ? infoHeight : 0,
        ease: 'power4.out',
      });
    }
  }, [showAdInfo]);

  const handleClick = (event) => {
    if (event.key === 'Enter' || !event.key) {
      setShowAdsInfo((currentState) => {
        const newState = currentState.map((showDataState, idx) =>
          idx === index ? !showDataState : showDataState
        );
        return newState;
      });
    }
  };

  return (
    <div className={styles['ad-info']}>
      {isOfferDefault ? (
        <div className={styles['ad-info__header']}>
          <div className={styles['ad-info__headline']}>
            <p className={styles['ad-info__default']}>
              <span className={styles.default__icon} />
              {defaultMessage}
            </p>
          </div>
        </div>
      ) : (
        <Fragment>
          <div className={styles['ad-info__header']}>
            <div className={styles['ad-info__headline']}>
              <p>Offer Information</p>
            </div>
            <div
              className={infoPanelClasses}
              onClick={handleClick}
              onKeyDown={handleClick}
              tabindex="0"
              id={gaEvent.tag}
              data-test="ad-info-button"
            />
          </div>
          <div
            ref={infoRef}
            className={styles['ad-info__content']}
            data-test="ad-info-content"
          >
            <AdInfoList adData={adData} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export { AdInfo };
