import { Fragment, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import { useGoogleAnalyticsEventsContext } from '../../hook/GoogleAnalyticsEventsContext';
import { useDisplayModeContext } from '../../hook/DisplayModeContext';

import { cx } from 'utils/cx';
import { AdInfo } from '../AdInfo';
import { Loader } from '../Loader';
import { ShareAd } from '../ShareAd';
import { Indicator } from './Indicator';
import { LastUpdate } from './LastUpdate';
import { PublishedAd } from './PublishedAd';
import { PreviewAd } from './PreviewAd';

import constants from '../../constants';
import styles from './Ad.scss';

const Ad = ({
  adSpecs,
  setShowAdsInfo,
  setUnits,
  showAdInfo,
  index,
  offersStore,
}) => {
  const { isPublishedMode } = useDisplayModeContext();
  const googleAnalyticsEvents = useGoogleAnalyticsEventsContext();

  const { defaultText } = constants;
  const { source, loadingData, dataWidth, dataHeight } = adSpecs;

  const [isOfferDefault, setOfferDefault] = useState(!source);
  const [previewAdSpecs, setPreviewAdSpecs] = useState(adSpecs);
  const [isPreviewLoading, setPreviewLoading] = useState(true);
  const [isPreviewActive, setPreviewActive] = useState(true);
  const [offerUpdateDate, setOfferUpdateDate] = useState();

  // Function to get the CSS class for the ad based on dimensions
  const adContainerClasses = cx(styles, [
    'ad__container',
    `ad--${dataWidth}x${dataHeight}`,
  ]);

  // Effect to update `isOfferDefault` based on the type of offer and source data
  useEffect(() => {
    setOfferDefault(() => {
      if (isPublishedMode) {
        return !source;
      }
      return !previewAdSpecs.source;
    });
  }, [isPublishedMode, source, previewAdSpecs]);

  // Function to render the preview ad
  const renderPreviewAd = () => {
    if (!isPublishedMode) {
      return (
        <div className={styles['ad__unit-preview']}>
          <PreviewAd
            adSpecs={previewAdSpecs}
            setLoading={setPreviewLoading}
            setSpecs={setPreviewAdSpecs}
            setUnits={setUnits}
            setActive={setPreviewActive}
            setUpdateDate={setOfferUpdateDate}
            offersStore={offersStore}
          />
        </div>
      );
    }
  };

  // Function to render ad info section
  const renderAdInfo = () => {
    let isLoading = isPreviewLoading;
    let specs = previewAdSpecs;

    if (isPublishedMode) {
      isLoading = loadingData;
      specs = adSpecs;
    }

    const { dataHeight, dataSource, source, zipCode } = specs;
    if (isLoading) {
      return (
        <div className={styles.ad__loader}>
          <Loader dataHeight={dataHeight} />
        </div>
      );
    }

    return (
      <article className={styles.ad__content}>
        <header>
          <div className={styles['ad__header-info']}>
            <div className={styles.ad__zip}>
              <div className={styles.zip__text}>Zip Code {zipCode}</div>
            </div>
            <div className={styles.ad__share}>
              <ShareAd zipCode={zipCode} />
            </div>
          </div>
          <div className={styles.ad__title}>
            {isOfferDefault ? (
              <Fragment>
                <h4>{dataSource}:&nbsp;</h4>
                <h4>{defaultText} </h4>
              </Fragment>
            ) : (
              <Fragment>
                <h4>{dataSource}:&nbsp;</h4>
                <h4>{source ? source.offerTemplateName : ''}</h4>
              </Fragment>
            )}
          </div>
        </header>
        <AdInfo
          gaEvent={googleAnalyticsEvents.offerInfoClick}
          adData={specs}
          showAdInfo={showAdInfo}
          setShowAdsInfo={setShowAdsInfo}
          index={index}
        />
      </article>
    );
  };

  return (
    <article
      className={adContainerClasses}
      tabIndex="0"
      ariaLabel={`Offer result ${adSpecs.adCount + 1}`}
      data-test={`ad-result-${adSpecs.adCount + 1}`}
    >
      <div className={styles.ad__item}>
        <header className={styles.ad__header} tabIndex="0">
          <Indicator isPreviewActive={isPreviewActive} />
          {offerUpdateDate && <LastUpdate date={offerUpdateDate} />}
        </header>
        <PublishedAd adSpecs={adSpecs} isHidden={!isPublishedMode} />
        {renderPreviewAd()}
        {renderAdInfo()}
      </div>
    </article>
  );
};

export default Ad;

Ad.propTypes = {
  adSpecs: PropTypes.object,
  setShowAdsInfo: PropTypes.func,
  setUnits: PropTypes.func,
  showAdInfo: PropTypes.bool,
  index: PropTypes.number,
  offersStore: PropTypes.object,
};

export { Ad };
