import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useState } from 'react';
import styles from './SuccessMessage.scss';

const SuccessMessage = ({ successText, successFLag }) => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setSuccess(successFLag);
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  }, [successFLag]);

  return (
    <div className={styles['success-message']}>
      {success ? (
        <div className={styles['success-message__loader']} />
      ) : (
        <div className={styles['success-message__text']}>{successText}</div>
      )}
    </div>
  );
};

export { SuccessMessage };
