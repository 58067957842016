import { getDynamicAvailableYears } from 'utils/getDynamicAvailableYears';

const formData = {
  brand: [
    {
      display: 'Select Brand',
      hiddenItem: true,
      value: '',
      model: [
        {
          value: '',
          hiddenItem: true,
          display: 'Select Model',
        },
      ],
    },
    {
      display: 'Alfa Romeo',
      value: 'Alfa Romeo',
      dataDivisionCode: 'Y',
      model: [],
    },
    {
      display: 'CHRYSLER',
      value: 'Chrysler',
      dataDivisionCode: 'C',
      model: [],
    },
    {
      display: 'Dodge',
      value: 'Dodge',
      dataDivisionCode: 'D',
      model: [],
    },
    {
      display: 'Jeep',
      value: 'Jeep',
      dataDivisionCode: 'J',
      model: [],
    },
    {
      display: 'Ram',
      value: 'Ram',
      dataDivisionCode: 'T',
      model: [],
    },
    {
      display: 'FIAT',
      value: 'Fiat',
      dataDivisionCode: 'X',
      model: [],
    },
  ],
  modelYear: [
    {
      hiddenItem: true,
      display: 'Select Year',
      value: '',
    },
    ...getDynamicAvailableYears(),
  ],
  size: [
    {
      value: '',
      hiddenItem: true,
      display: 'Select Size',
    },
    {
      dataPlacementId: '282382495',
      dataWidth: '160',
      dataHeight: '600',
      value: '160x600',
      display: '160x600',
    },
    {
      dataPlacementId: '282351471',
      dataWidth: '300',
      dataHeight: '250',
      value: '300x250',
      display: '300x250',
    },
    {
      dataPlacementId: '282382804',
      dataWidth: '300',
      dataHeight: '600',
      value: '300x600',
      display: '300x600',
    },
    {
      dataPlacementId: '282161486',
      dataWidth: '728',
      dataHeight: '90',
      value: '728x90',
      display: '728x90',
    },
  ],
};

const { brand, size, modelYear } = formData;
const [firstBrand] = brand;
const [firstModel] = firstBrand.model;
const [firstSize] = size;
const [firstModelYear] = modelYear;

export {
  brand,
  size,
  modelYear,
  firstBrand,
  firstModel,
  firstSize,
  firstModelYear,
  formData,
};
