import { h } from 'preact';
import ContentLoader from 'react-content-loader';
import styles from './Loader.scss';

const Loader = ({ dataHeight }) => {
  const heightInfo = 140;
  const height = parseInt(dataHeight);
  const heightRect = 24;
  const padding = 14;

  return (
    <ContentLoader
      height={height + heightInfo + heightRect + padding}
      className={styles.loader}
    >
      <rect
        x="15"
        y="6px"
        height={heightRect}
        className={styles['loader__rect-indicator']}
      />
      <rect
        x="0"
        y={heightRect + padding}
        height={height}
        className={styles['loader__rect-image']}
      />
      <rect
        x="15"
        y={height + heightRect + padding * 2}
        height={heightRect}
        className={styles['loader__rect-title']}
      />
      <rect
        x="15"
        y={height + heightRect * 2 + padding * 3}
        height={heightRect}
        className={styles['loader__rect-subtitle']}
      />
      <rect
        x="15"
        y={height + heightRect * 3 + padding * 5}
        height={heightRect}
        className={styles['loader__rect-text']}
      />
    </ContentLoader>
  );
};

export { Loader };
