import { h } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import { cx } from 'utils/cx';

import constants from '../../../constants';
import styles from './Indicator.scss';
import { useDisplayModeContext } from 'hook/DisplayModeContext';

const Indicator = ({ isPreviewActive }) => {
  const { displayMode } = constants;
  const [classes, setClasses] = useState(styles.indicator);
  const [content, setContent] = useState('');
  const [contentHover, setContentHOver] = useState('');
  const [ariaLabel, setAriaLabel] = useState('');
  const { displayMode: displayModeValue } = useDisplayModeContext();

  const processIndicator = useCallback(() => {
    const { indicatorValue, indicatorAriaLabel, value, indicatorHoverValue } =
      displayMode[displayModeValue];

    let classModifier = '';
    let displayValue = indicatorValue;
    let displayValueHover = indicatorHoverValue;
    let tempAriaLabel = indicatorAriaLabel;

    if (value === displayMode.preview.value) {
      classModifier = isPreviewActive ? '-active' : '-inactive';
      displayValue = isPreviewActive
        ? indicatorValue.active
        : indicatorValue.inactive;
      displayValueHover = isPreviewActive
        ? indicatorHoverValue.active
        : indicatorHoverValue.inactive;
      tempAriaLabel = isPreviewActive
        ? indicatorAriaLabel.active
        : indicatorAriaLabel.inactive;
    }

    const classes = cx(styles, [
      'indicator',
      `indicator--${value}${classModifier}`,
    ]);

    return {
      classes,
      content: displayValue,
      ariaLabel: tempAriaLabel,
      contentHover: displayValueHover,
    };
  }, [isPreviewActive, displayMode, displayModeValue]);

  useEffect(() => {
    const { classes, content, ariaLabel, contentHover } = processIndicator();
    setClasses(classes);
    setContent(content);
    setContentHOver(contentHover);
    setAriaLabel(ariaLabel);
  }, [processIndicator]);

  return (
    <div className={styles.indicator__main}>
      <div className={classes} ariaLabel={ariaLabel}>
        {content}
      </div>
      <div className={styles.indicator__hover}>{contentHover}</div>
    </div>
  );
};

Indicator.propTypes = {
  isPreviewActive: PropTypes.bool,
};

export { Indicator };
