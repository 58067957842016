import { Fragment, h } from 'preact';
import styles from './AdInfoList.scss';

const ListItem = ({ item, value }) => (
  <li>
    <strong>{item}:</strong> {value}
  </li>
);

const AdInfoList = ({ adData }) => {
  const { dataSource, source, family } = adData;
  const {
    offerId,
    offerTemplateName,
    offerType,
    startDate,
    dma,
    bccode,
    model,
    shortModelCode,
    modelyear,
    expiryDate,
    zip,
  } = source;
  const rZipCode = Array.isArray(zip) ? zip[0] : zip;

  return (
    <ul className={styles['ad-info__list']}>
      <ListItem item={'Template Name'} value={offerTemplateName} />
      <ListItem item={'Data Source'} value={dataSource} />
      {offerType && (
        <Fragment>
          <ListItem item={'Nameplate'} value={model} />
          <ListItem item={'MY'} value={modelyear} />
          <ListItem item={'Offer Type'} value={offerType} />
          <ListItem
            item={'Start Date'}
            value={startDate.rawvalue || startDate || ''}
          />
          <ListItem
            item={'End Date'}
            value={expiryDate.rawvalue || expiryDate || ''}
          />
          <ListItem item={'Short Model Code'} value={shortModelCode} />
          <ListItem item={'BC-DMA'} value={`${bccode} - ${dma}`} />
          <ListItem item={'R-ZipCode'} value={rZipCode} />
          <ListItem item={'Family Code'} value={family} />
          {offerId && <ListItem item={'Offer Id'} value={offerId} />}
        </Fragment>
      )}
    </ul>
  );
};

export { AdInfoList };
