import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';

import { useGoogleAnalyticsEventsContext } from '../../../hook/GoogleAnalyticsEventsContext';

import { cx } from 'utils/cx';
import { generateAdTemplate } from 'utils/generateAdTemplate';
import constants from '../../../constants';
import styles from '../Ad.scss';

const PublishedAd = ({ adSpecs, isHidden = false }) => {
  const googleAnalyticsEvents = useGoogleAnalyticsEventsContext();
  const { dcmScriptTag } = constants;
  const adElement = useRef(null);

  const adDataTemplate = generateAdTemplate(adSpecs);

  const createMarkup = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    const [insElement] = adElement.current.children;
    if (adDataTemplate && insElement) {
      window.postscribe(insElement, dcmScriptTag);
    }
  }, [adElement, adDataTemplate, dcmScriptTag]);

  /**
   * Renders the Published Ad invisible.
   * Trying to alternate the render of the Ad did not work with the
   * injection of the ins tag to render the live ad from Google.
   * Has to remain on the viewport but not displayed.
   */
  const adUnitClasses = cx(styles, [
    'ad__unit',
    isHidden && 'ad__unit--hidden',
  ]);

  return (
    <div
      id={googleAnalyticsEvents.adClick.tag}
      className={adUnitClasses}
      ref={adElement}
      dangerouslySetInnerHTML={createMarkup(adDataTemplate)}
    />
  );
};

PublishedAd.propTypes = {
  adSpecs: PropTypes.object,
  isHidden: PropTypes.bool,
};

export { PublishedAd };
