import { h } from 'preact';
import PropTypes from 'prop-types';
import styles from './Fieldset.scss';
import constants from '../../constants';
const {
  form: { zipCodes: zipCodesLabel, zipCodeLabelLegend },
} = constants;

const Fieldset = ({ children, htmlFor, label }) => {
  return (
    <fieldset className={styles.fieldset}>
      <label className={styles.fieldset__label} htmlFor={htmlFor}>
        {label}
        <sup className={styles.fieldset__sup}>*</sup>
      </label>
      {children}
      {label === zipCodesLabel && (
        <label className={styles['fieldset__label--zipcode']} htmlFor={htmlFor}>
          {zipCodeLabelLegend}
        </label>
      )}
    </fieldset>
  );
};

Fieldset.propTypes = {
  children: PropTypes.object.isRequired,
  htmlFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export { Fieldset };
