import { useState, useEffect } from 'preact/hooks';
import constants from '../../constants';
import { isMobile } from '../../utils/mobileBrowser';

function useViewports() {
  const [mainContentViewHeight, setMainContentViewHeight] = useState(0);
  const [menuMobileViewHieght, setMenuMobileViewHieght] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isLansdcapeMobile, setIsLansdcapeMobile] = useState(false);
  const { mobileBreakpoint } = constants;
  const headerHeight = 99; // TODO get this dynamically

  useEffect(() => {
    const handleViewports = () => {
      setViewportWidth(window.innerWidth);
    };

    handleViewports();

    window.addEventListener('resize', handleViewports);
  }, []);

  useEffect(() => {
    const handleViewports = () => {
      setViewportHeight(window.innerHeight);
    };

    handleViewports();

    // Solved error Chrome IOS reading window.innerHeight
    window.addEventListener('orientationchange', handleViewports);

    window.addEventListener('resize', handleViewports);
  }, []);

  useEffect(() => {
    setIsDesktop(viewportWidth > mobileBreakpoint);
  }, [mobileBreakpoint, viewportWidth]);

  useEffect(() => {
    setMainContentViewHeight(viewportHeight - headerHeight);
  }, [headerHeight, viewportHeight]);

  useEffect(() => {
    // this gap is a static value, just for alignment in mobile version
    const gap = !isDesktop ? 10 : 0;
    const menuTopGap = headerHeight + gap;

    setMenuMobileViewHieght(viewportHeight - menuTopGap);
  }, [headerHeight, isDesktop, viewportHeight]);

  useEffect(() => {
    setIsLansdcapeMobile(isMobile && viewportWidth > viewportHeight);
  }, [viewportHeight, viewportWidth]);

  return {
    mainContentViewHeight,
    menuMobileViewHieght,
    viewportWidth,
    viewportHeight,
    isDesktop,
    isLansdcapeMobile,
    setMainContentViewHeight,
    setMenuMobileViewHieght,
    setViewportWidth,
    setViewportHeight,
    setIsDesktop,
    setIsLansdcapeMobile,
  };
}

export { useViewports };
