import { useEffect, useState } from 'preact/hooks';

function usePublishMode(isPublishedMode) {
  const [openPopUp, setOpenPopUp] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (isPublishedMode && !window.localStorage.getItem('DontShow')) {
      setOpenPopUp(true);
    }
  }, [isPublishedMode]);

  const handleClosePopUp = () => {
    if (checked) {
      window.localStorage.setItem('DontShow', 'true');
    }
    setOpenPopUp(false);
  };

  return { openPopUp, handleClosePopUp, checked, setChecked };
}

export { usePublishMode };
