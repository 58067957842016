import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

import { size, modelYear, formData } from '../../data';

import constants from '../../constants';

import { FieldError } from '../FieldError';
import { ChipsInput } from '../ChipsInput';
import { Fieldset } from '../Fieldset';
import { Dropdown } from '../Dropdown';
import { Button } from '../Button';

import { useViewports } from '../../hook/useViewports';
import { useFieldsContext } from '../../hook/FieldsContext';
import { useDataListContext } from '../../hook/DataListContext';
import { useGoogleAnalyticsEventsContext } from '../../hook/GoogleAnalyticsEventsContext';

import styles from './AdForm.scss';

const {
  form: {
    brand: brandLabel,
    model: modelLabel,
    modelYear: modelYearLabel,
    size: sizeLabel,
    zipCodes: zipCodesLabel,
    submitButton: submitButtonLabel,
  },
  parameters: {
    model: MODEL_PARAM,
    brand: BRAND_PARAM,
    size: SIZE_PARAM,
    modelYear: MODELYEAR_PARAM,
    zipCodes: ZIPCODES_PARAM,
  },
} = constants;

const AdForm = () => {
  const { fieldsData, supportedModels } = useFieldsContext();
  const {
    adsInfo,
    loadingUnits,
    appIntroAnimation,
    dataForm,
    sideMenu,
    mainAdsScrolling,
  } = useDataListContext();
  const googleAnalyticsEvents = useGoogleAnalyticsEventsContext();
  const { brand, loading } = supportedModels;
  const [enableLoadAdBTN, setEnableLoadAdBTN] = useState(false);
  const [modelsError, setModelsError] = useState(false);
  const [firstRender, setFirstRender] = useState(0);
  const { isDesktop } = useViewports();
  const [openSideMenu, setOpenSideMenu] = sideMenu;
  const [fields, setFields] = fieldsData;

  const [fieldsError, setFieldsError] = useState({
    brand: false,
    model: false,
    size: false,
    modelYear: false,
    zipCodes: false,
  });
  const [, setDataFromForm] = dataForm;
  const [, setShowAdsInfo] = adsInfo;
  const [unitsAreLoading, setUnitsAreLoading] = loadingUnits;
  const [appIntroAnimationEnd] = appIntroAnimation;
  const [, setMainAdsScroll] = mainAdsScrolling;

  const isElementFilled = ([key, field]) => {
    switch (key) {
      case ZIPCODES_PARAM:
        return field.length;
      default:
        return !!field.value;
    }
  };

  const fieldsNotFilled = () => {
    const fieldsValidate = {};
    Object.entries(fields).forEach(([key, field]) => {
      fieldsValidate[key] = !isElementFilled([key, field]);
    });

    return fieldsValidate;
  };

  useEffect(() => {
    if (modelsError) {
      return;
    }

    const flag = Object.entries(fields).every(isElementFilled);

    setEnableLoadAdBTN(flag);
  }, [fields, modelsError]);

  // models by brand selected
  const { model } = brand.find((b) => b.value === fields.brand.value);

  const onDropdownItemClick = ({ display, parentType }) => {
    let result;

    if (parentType === MODEL_PARAM) {
      // we need to go deeper within the models of the currently selected brand.
      [result] = model.filter((m) => m.display === display);
    } else {
      // for the fields that are not model, we can move the object key dynamically
      // e.g: formData['brand'] ---> [{ value: 'Dodge', ...}, ...]
      result = formData[parentType].find((obj) => obj.display === display);
    }

    setFields((prevFields) => ({ ...prevFields, [parentType]: result }));
  };

  const onDropdownItemClickBrand = ({ value }) => {
    const result = brand.find((obj) => obj.value === value);

    // when the brand changes, the selected model should be the first one
    setFields((prevFields) => ({
      ...prevFields,
      brand: result,
      model: result.model[0],
    }));
  };

  const createAdTemplates = () => {
    if (!fields.model) {
      setModelsError(true);
      return;
    }

    const {
      brand: { value: brandValue, dataDivisionCode },
      model: { dataModelCode },
      modelYear: { value: modelYearValue },
      size: { dataWidth, dataHeight, dataPlacementId },
      zipCodes,
    } = fields;
    const family = `U${dataDivisionCode}${modelYearValue}${dataModelCode}`;
    return {
      brandValue,
      dataDivisionCode,
      dataHeight,
      dataModelCode,
      dataPlacementId,
      dataWidth,
      family,
      modelYearValue,
      zipCodes,
    };
  };

  const adTemplates = createAdTemplates();

  const handleSubmit = (adTemplates) => {
    setUnitsAreLoading(true);
    setDataFromForm(adTemplates);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const _fieldsError = fieldsNotFilled();

    setFieldsError(_fieldsError);

    if (enableLoadAdBTN && !unitsAreLoading) {
      handleSubmit(adTemplates);

      setShowAdsInfo((currentState) => {
        return new Array(currentState.length).fill(true);
      });

      // Set the scrollTop for MainAds to 0 to better UX
      setMainAdsScroll(0);

      if (!isDesktop) {
        setOpenSideMenu(false);
      }
    }
  };

  const sideMenuOpen = openSideMenu ? ' ' + styles['side-menu--open'] : '';

  useEffect(() => {
    // Load units if the Fields are filled when link was shared
    if (
      enableLoadAdBTN &&
      firstRender === 0 &&
      appIntroAnimationEnd &&
      !loading
    ) {
      handleSubmit(adTemplates);
      setFirstRender(1);
    }
  }, [appIntroAnimationEnd, loading]);

  if (modelsError) return <FieldError errorText={constants.formError} />;

  return (
    <div className={styles.form}>
      <form className={styles.form__container}>
        <Fieldset htmlFor={BRAND_PARAM} label={brandLabel}>
          <Dropdown
            id={BRAND_PARAM}
            parentType={BRAND_PARAM}
            items={brand.filter(({ hiddenItem }) => !hiddenItem)}
            handlerClick={onDropdownItemClickBrand}
            gaEvent={googleAnalyticsEvents.brandClick.tag}
            hasErrors={fieldsError[BRAND_PARAM]}
            setFieldsError={setFieldsError}
            optionSelected={fields[BRAND_PARAM]}
          />
        </Fieldset>

        <Fieldset htmlFor={MODEL_PARAM} label={modelLabel}>
          <Dropdown
            id={MODEL_PARAM}
            parentType={MODEL_PARAM}
            items={model.filter(({ hiddenItem }) => !hiddenItem)}
            handlerClick={onDropdownItemClick}
            gaEvent={googleAnalyticsEvents.modelClick.tag}
            hasErrors={fieldsError[MODEL_PARAM]}
            setFieldsError={setFieldsError}
            optionSelected={fields[MODEL_PARAM]}
          />
        </Fieldset>

        <Fieldset htmlFor={SIZE_PARAM} label={sizeLabel}>
          <Dropdown
            id={SIZE_PARAM}
            parentType={SIZE_PARAM}
            items={size.filter(({ hiddenItem }) => !hiddenItem)}
            handlerClick={onDropdownItemClick}
            gaEvent={googleAnalyticsEvents.sizeClick.tag}
            hasErrors={fieldsError[SIZE_PARAM]}
            setFieldsError={setFieldsError}
            optionSelected={fields[SIZE_PARAM]}
          />
        </Fieldset>

        <Fieldset htmlFor={MODELYEAR_PARAM} label={modelYearLabel}>
          <Dropdown
            id={MODELYEAR_PARAM}
            parentType={MODELYEAR_PARAM}
            items={modelYear.filter(({ hiddenItem }) => !hiddenItem)}
            handlerClick={onDropdownItemClick}
            gaEvent={googleAnalyticsEvents.myClick.tag}
            hasErrors={fieldsError[MODELYEAR_PARAM]}
            setFieldsError={setFieldsError}
            optionSelected={fields[MODELYEAR_PARAM]}
          />
        </Fieldset>

        <Fieldset htmlFor={ZIPCODES_PARAM} label={zipCodesLabel}>
          <ChipsInput
            id={googleAnalyticsEvents.zipClick.tag}
            parentType={ZIPCODES_PARAM}
            hasErrors={fieldsError[ZIPCODES_PARAM]}
            setFieldsError={setFieldsError}
            optionSelected={fields[ZIPCODES_PARAM]}
            setOption={setFields}
          />
        </Fieldset>

        <div class={styles.form__button + sideMenuOpen}>
          <Button
            id={googleAnalyticsEvents.loadClick.tag}
            labelText={submitButtonLabel}
            handlerSubmit={onSubmit}
            ariaLabel={`Load Ad. ${enableLoadAdBTN ? '' : 'Disabled'}`}
          />
        </div>
      </form>
    </div>
  );
};
export { AdForm };
