import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';

import { cx } from '../../utils/cx';
import {
  isSafariIPhone,
  isChromeIPhone,
  isChromeAndroid,
} from '../../utils/mobileBrowser';

import constants from '../../constants';

import { useGoogleAnalyticsEventsContext } from '../../hook/GoogleAnalyticsEventsContext';
import { useDisplayModeContext } from '../../hook/DisplayModeContext';
import { useDataListContext } from '../../hook/DataListContext';
import { useViewports } from '../../hook/useViewports';

import styles from './SideMenu.scss';

const Btn = ({ className, handlerClick, text, gaEvent }) => (
  <div
    className={styles.btn + ' ' + className}
    onClick={handlerClick}
    onKeyDown={handlerClick}
    tabindex="0"
    id={gaEvent.tag}
  >
    <div className={styles.btn__icon} id={gaEvent.tag} />
    {text && <p className={styles.btn__text}>{text}</p>}
  </div>
);

const SideMenu = ({ children }) => {
  const { sideMenu, unitsData } = useDataListContext();
  const googleAnalyticsEvents = useGoogleAnalyticsEventsContext();
  const { isDesktop, menuMobileViewHieght, isLansdcapeMobile, viewportHeight } =
    useViewports();
  const { displayMode } = useDisplayModeContext();
  const [openSideMenu, setOpenSideMenu] = sideMenu;
  const [units] = unitsData;
  const {
    copies: { setFilters, mofifyFilters },
  } = constants;
  const menuContainer = useRef(null);

  const dataListEmpty = units.length === 0;

  const sideMenuClasses = cx(styles, [
    'side-menu',
    !openSideMenu ? 'side-menu--closed' : 'side-menu--open',
    isSafariIPhone && 'side-menu__safari-iphone',
    isChromeIPhone && 'side-menu__chrome-iphone',
    isChromeAndroid && 'side-menu__chrome-android',
    isLansdcapeMobile && 'side-menu--landscape-mobile',
    `side-menu--${displayMode}-mode`,
  ]);

  const sideMenuBtnClasses = cx(styles, [
    'side-menu__btn',
    !isDesktop && !dataListEmpty && 'side-menu__btn--enable',
  ]);

  const handleClick = (event) => {
    if (event.key === 'Enter' || !event.key) {
      setOpenSideMenu((currentState) => !currentState);
      menuContainer.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (!isDesktop) {
      setOpenSideMenu(false);
    } else {
      setOpenSideMenu(true);
    }
  }, [isDesktop, setOpenSideMenu]);

  const heightSideMenu = !isLansdcapeMobile
    ? { height: menuMobileViewHieght }
    : { height: viewportHeight };

  return (
    <aside className={sideMenuClasses}>
      <div className={styles['side-menu__header--mobile']}>
        <h3 className={styles['side-menu__title--mobile']}>{setFilters}</h3>
        <Btn
          gaEvent={googleAnalyticsEvents.hideMenuClick}
          className={styles['btn--close']}
          handlerClick={handleClick}
        />
      </div>
      <div
        className={styles['side-menu__container']}
        ref={menuContainer}
        style={heightSideMenu}
      >
        <h3 className={styles['side-menu__title']}>{setFilters}</h3>
        {children}
      </div>
      <div className={sideMenuBtnClasses}>
        <Btn
          gaEvent={googleAnalyticsEvents.hideMenuClick}
          className={styles['btn--open']}
          handlerClick={handleClick}
          text={mofifyFilters}
        />
      </div>
    </aside>
  );
};

export { SideMenu };
