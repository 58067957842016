import constants from '../../constants';

/**
 * Generates dynamic content based on brand and ad specifications.
 * @param {string} brand - The brand of the vehicle.
 * @param {Object} adSpecs - The ad specifications.
 * @returns {string} data.omscows - The omscows data name.
 * @returns {Object} data.newDevDynamicContent - The generated dynamic content.
 */
const getDynamicContent = (brand, adSpecs) => {
  const { omscowsLabels, jbAssetsBasePath } = constants;
  const { source } = adSpecs;
  if (!source) {
    return {};
  }

  // Construct parent label
  const parentLabel = `${brand}-efv-nonendemic-${source.modelyear}`;

  // Define template
  const template = `efp-${brand}`;

  // Get OMSCOWS label based on brand
  const omscows = omscowsLabels[brand];

  // Construct the new dynamic content object
  const newDynamicContent = {
    parent: [
      {
        id: 1,
        label: parentLabel,
        rpt_dimension_year: [source.modelyear],
        rd_template: template,
        creative_type: ['non-endemic'],
        template: template,
      },
    ],
    [omscows]: [
      {
        ...source,
        startDate: {
          RawValue: source.startDate,
        },
        vehname: {
          Type: 'file',
          Url: `${jbAssetsBasePath}${source.vehname}`,
        },
        expiryDate: {
          RawValue: source.expiryDate,
        },
      },
    ],
  };

  return newDynamicContent;
};

export { getDynamicContent };
