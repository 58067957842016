import { getDocs, collection } from 'firebase/firestore';
import firestore from '../';
import { getActiveModelYear } from '../getActiveModelYear/';
import { getGeolocation } from '../getGeolocation';
import { getMsrpQuery, getMsrpOffer } from './config/msrp';
import { getCowsQuery, getCowsOffer } from './config/cows';
import { getOmsQuery, getOmsOffer } from './config/oms';
import constants from 'constants.js';

/**
 * Retrieves offer data based on provided criteria.
 * @param {Object} criteria - The criteria for the offer data.
 * @param {string} criteria.divisionCode - The division code of the vehicle.
 * @param {string} criteria.shortModelCode - The short model code of the vehicle.
 * @param {number} criteria.modelyear - The model year of the vehicle.
 * @param {string} criteria.zip - The zip code.
 * @returns {Promise<Object>} - A promise resolving to the offer data.
 */
export const getOfferData = async (queryData) => {
  const { offerDefault } = constants;
  const geolocation = await getGeolocation(queryData);
  const activeMY = await getActiveModelYear({ ...queryData, ...geolocation });
  const isActiveMY = activeMY === queryData.modelyear;
  const allData = {
    ...geolocation,
    ...queryData,
    isActiveMY,
  };
  const defaultAllData = {
    ...offerDefault,
    isActiveMY,
  };

  try {
    // Getting data from collections
    // Checks for COWS and if there is, returns the COWS Offer.
    const cowsQuery = getCowsQuery({
      collection: collection(firestore, process.env.COWS_COLLECTION_NAME),
      ...allData,
    });
    const cowsData = await getDocs(cowsQuery);
    if (cowsData.size > 0) {
      return getCowsOffer({ data: cowsData, ...allData });
    }

    // If NO COWS data is found:
    // Checks for OMS and if there is, returns the OMS Offer.
    const omsQuery = getOmsQuery({
      collection: collection(firestore, process.env.OMS_COLLECTION_NAME),
      ...allData,
    });
    const omsData = await getDocs(omsQuery);
    if (omsData.size > 0) {
      // The offer on the OMS documents are bunch together per year.
      // i.e: Jeep could have 4 offers in a FB document for 2022.
      // That's the main difference between OMS and COWS or OMS, this is why the validation.
      const omsOffer = getOmsOffer({ data: omsData, ...allData });

      if (omsOffer) {
        return omsOffer;
      }
    }

    // If NO COWS and OMS data is found:
    // Checks for MSRP and if there is, returns the MSRP Offer.
    const msrpQuery = getMsrpQuery({
      collection: collection(firestore, process.env.MSRP_COLLECTION_NAME),
      ...allData,
    });
    const msrpData = await getDocs(msrpQuery);
    if (msrpData.size > 0) {
      return getMsrpOffer({ data: msrpData, ...allData });
    }

    // If no data found for COWS, OMS or MSRP, return default offer
    return defaultAllData;
  } catch (err) {
    // If there's an error, return default offer
    return defaultAllData;
  }
};
