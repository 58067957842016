import { h } from 'preact';
import { useRef, useEffect } from 'preact/hooks';
import { MuiChipsInput } from 'mui-chips-input';
import { FieldError } from '../FieldError';
import { useViewports } from '../../hook/useViewports';
import constants from '../../constants';
import styles from './ChipsInput.scss';
import { cx } from '../../utils/cx';
import { testIsNotDigit } from '../../utils/regex';
const {
  form: { zipErrorMessage },
  parameters: { zipCodes: ZIPCODES_PARAM },
} = constants;

const ChipsInput = ({
  id,
  parentType,
  hasErrors,
  setFieldsError,
  optionSelected,
  setOption,
}) => {
  const { isLansdcapeMobile } = useViewports();
  const zipCodeInput = useRef(null);

  const onChangeZipCode = (zip) => {
    setOption((prevFields) => ({
      ...prevFields,
      [parentType]: zip,
    }));
    setFieldsError((prevFieldError) => ({
      ...prevFieldError,
      [parentType]: false,
    }));
  };

  const validateChipsLenght = (chipValue) => ({
    isError: chipValue.length < 5,
    textError: zipErrorMessage,
  });

  const handlePaste = (e) => {
    const valueFromClipBoard = e.clipboardData.getData('text');
    testIsNotDigit(valueFromClipBoard) && e.preventDefault();
  };

  const handleKeyPress = (e) => testIsNotDigit(e.key);

  const onInputChange = (e) => {
    // This prevent the special cacacters (`,´)
    if (testIsNotDigit(e.target.value)) {
      e.target.value = e.target.value.substr(0, e.target.value.length - 1);
    }
  };

  const onInputBlur = () => {
    if (!optionSelected.length) {
      setFieldsError((prevFieldError) => ({
        ...prevFieldError,
        [parentType]: true,
      }));
    }
  };

  useEffect(() => {
    // If the focus is on Input-field, close the keyboard
    // when the orientation change to landscape
    if (isLansdcapeMobile) {
      zipCodeInput.current.blur();
    }
  }, [isLansdcapeMobile]);

  const chipsInputFieldInputClasses = cx(styles, [
    optionSelected.length >= 9 && 'chips-input__input--hide', // Validate the input to have max 9 ZipCodes
  ]);

  const chipsInputContainerClasses = cx(styles, [
    'chips-input',
    hasErrors && 'chips-input__field--error',
  ]);

  return (
    <div className={styles['chips-input']}>
      <div className={chipsInputContainerClasses}>
        <MuiChipsInput
          clearInputOnBlur
          disableEdition
          placeholder=""
          type="text"
          className={styles['chips-input__field']}
          value={optionSelected}
          dataTest={parentType}
          parentType={ZIPCODES_PARAM}
          onChange={onChangeZipCode}
          validate={validateChipsLenght}
          onPaste={handlePaste}
          onKeyDown={handleKeyPress}
          inputProps={{
            ref: zipCodeInput,
            id: id,
            className: chipsInputFieldInputClasses,
            maxLength: 5,
            minLength: 5,
            size: 5,
            tabIndex: 0,
            onChange: onInputChange,
            onBlur: onInputBlur,
            ariaLabel: `${parentType} chip input. Required`,
            dataTest: `${parentType}Input`,
          }}
        />
      </div>
      {hasErrors && <FieldError />}
    </div>
  );
};

export { ChipsInput };
