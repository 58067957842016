import { Fragment, h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import { InformationIcon } from '../Icons/InformationIcon';
import { cx } from 'utils/cx';

import constants from '../../constants';
import styles from './InformationBanner.scss';
import { Link } from 'react-router-dom';

const InformationBanner = () => {
  const [shouldDisplay, setDisplay] = useState(true);
  const [isVertical, setVertical] = useState(false);
  const { userGuideLink, displayMode } = constants;
  const bannerRef = useRef(null);
  const { value, displayValue, information } = displayMode.published;
  const {
    hugeModePopUp: { btnGoBack },
  } = constants;

  // Effect to check if the banner should be displayed based on close time
  useEffect(() => {
    // Retrieve the last close time of the information banner from sessionStorage
    const infoCloseTime = window.sessionStorage.getItem('infoCloseTime');
    const now = Date.now();

    // Calculate the time difference in hours since the banner was last closed
    const oneHourAgo = Math.abs(infoCloseTime - now) / (1000 * 60 * 60) >= 1; // Convert milliseconds to hours

    // If the time difference is less than 1 hour, set shouldDisplay to false (don't display the banner)
    if (!oneHourAgo) {
      setDisplay(false);
    }
  }, []);

  // Effect to determine if the banner is vertical based on dimensions
  useEffect(() => {
    if (bannerRef.current) {
      setVertical(
        bannerRef.current.clientHeight > 68 &&
          bannerRef.current.clientWidth < 700
      );
    }
  });

  // Function to get the banner's CSS classes
  const getBannerClasses = () => {
    return cx(styles, [
      'information-banner',
      `information-banner--${value}`,
      isVertical ? 'information-banner--vertical' : '',
    ]);
  };

  if (!shouldDisplay) return <Fragment />;

  return (
    <div ref={bannerRef} className={getBannerClasses()} tabIndex="0">
      <InformationIcon />
      <b className={styles[['information-banner__display-mode']]}>
        {displayValue}
      </b>
      <span className={styles[['information-banner__content']]}>
        {information}
      </span>
      <Link
        to="/"
        href={userGuideLink}
        reloadDocument
        className={styles['information-banner__go-back']}
        ariaLabel={'Go back to EFP'}
      >
        {btnGoBack}
      </Link>
    </div>
  );
};

export { InformationBanner };
