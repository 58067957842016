import { h } from 'preact';
import { useRef } from 'preact/hooks';
import { useDataListContext } from '../../hook/DataListContext';
import { useViewports } from '../../hook/useViewports';
import constants from '../../constants';
import styles from './Switch.scss';

const Switch = ({
  showLabel,
  showAllAdsData,
  showGATag,
  hideLabel,
  hideAllAdsData,
  hideGATag,
}) => {
  const { isDesktop } = useViewports();
  const { adsInfo } = useDataListContext();
  const [showAdsInfo] = adsInfo;
  const btnBar = useRef(0);
  const btnA = useRef(0);
  const btnB = useRef(0);
  const { mobileShowLabel } = constants;

  const isInfoShowed = ([key, field]) => {
    return !!field;
  };

  const handleClickShowInfo = (event) => {
    if (event.key === 'Enter' || !event.key) {
      showAllAdsData();
    }
  };

  const handleClickHideInfo = (event) => {
    if (event.key === 'Enter' || !event.key) {
      hideAllAdsData();
    }
  };

  const allUnitsInfoShowed = Object.entries(showAdsInfo).every(isInfoShowed);
  const barShowState = allUnitsInfoShowed ? ' ' + styles['btn__bar--show'] : '';
  const contentShowState = allUnitsInfoShowed
    ? ' ' + styles['btn__content--show-state']
    : '';
  const hideBtnHideInfo = !allUnitsInfoShowed
    ? ' ' + styles['btn__info--hide']
    : '';
  const getBTNBarPosition = (btnRef) => {
    if (!isDesktop) {
      return {};
    }

    return {
      transform: btnRef === btnA ? 'translateX(0)' : 'translateX(100%)',
    };
  };
  const barPropertiesStyles = allUnitsInfoShowed
    ? getBTNBarPosition(btnA)
    : getBTNBarPosition(btnB);

  return (
    <div className={styles.btn__container}>
      <div className={styles['btn__label-mobile']}>{mobileShowLabel}</div>
      <div className={styles.btn__content + contentShowState}>
        <div
          className={styles.btn__bar + barShowState}
          ref={btnBar}
          style={barPropertiesStyles}
        />
        <button
          className={styles.btn__info}
          onClick={handleClickShowInfo}
          ref={btnA}
          type="button"
          tabIndex="0"
          id={showGATag}
        >
          <span className={styles.btn__text}>{showLabel}</span>
        </button>
        <button
          className={styles.btn__info + hideBtnHideInfo}
          onClick={handleClickHideInfo}
          ref={btnB}
          type="button"
          tabIndex="0"
          id={hideGATag}
        >
          <span className={styles.btn__text}>{hideLabel}</span>
        </button>
      </div>
    </div>
  );
};

export { Switch };
