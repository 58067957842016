import { h } from 'preact';
import styles from './FieldError.scss';
import constants from '../../constants';

const { fieldRequired } = constants;

const FieldError = ({ errorText = fieldRequired }) => {
  return (
    <div className={styles.error__main}>
      <i className={styles.error__icon} />
      <p className={styles.error__text}>{errorText}</p>
    </div>
  );
};

export { FieldError };
