import { h } from 'preact';

const ClockIcon = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 4.875V8L9.0625 8.9375M13.125 8C13.125 11.1066 10.6066 13.625 7.5 13.625C4.3934 13.625 1.875 11.1066 1.875 8C1.875 4.8934 4.3934 2.375 7.5 2.375C10.6066 2.375 13.125 4.8934 13.125 8Z" />
    </svg>
  );
};

export default ClockIcon;
