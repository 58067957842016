import { h } from 'preact';
import { Router } from './Router';
import { DataListProvider } from '../hook/DataListContext';
import { DisplayModeProvider } from '../hook/DisplayModeContext';
import { FieldsProvider } from '../hook/FieldsContext';
import { GoogleAnalyticsEventsProvider } from '../hook/GoogleAnalyticsEventsContext';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  return (
    <div id="preact_root">
      <BrowserRouter>
        <FieldsProvider>
          <DisplayModeProvider>
            <DataListProvider>
              <GoogleAnalyticsEventsProvider>
                <Router />
              </GoogleAnalyticsEventsProvider>
            </DataListProvider>
          </DisplayModeProvider>
        </FieldsProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
