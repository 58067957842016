import { h, createContext } from 'preact';
import { useContext } from 'preact/hooks';
import { useDisplayMode } from '../useDisplayMode';
import { usePublishMode } from '../usePopUpHugeMode';

const DisplayModeContext = createContext();

const DisplayModeProvider = ({ children }) => {
  const [displayMode, isPublishedMode] = useDisplayMode();
  const { openPopUp, handleClosePopUp, checked, setChecked } =
    usePublishMode(isPublishedMode);

  return (
    <DisplayModeContext.Provider
      value={{
        displayMode,
        isPublishedMode,
        publishModePopUp: {
          openPopUp,
          handleClosePopUp,
          checked,
          setChecked,
        },
      }}
    >
      {children}
    </DisplayModeContext.Provider>
  );
};

const useDisplayModeContext = () => useContext(DisplayModeContext);
export { DisplayModeProvider, DisplayModeContext, useDisplayModeContext };
