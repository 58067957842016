import { useState, useEffect } from 'preact/hooks';
import { useLocation } from 'react-router-dom';

import constants from '../../constants';

function useDisplayMode() {
  const {
    displayMode: { published: PUBLISHED_MODE, preview: PREVIEW_MODE },
  } = constants;
  const location = useLocation();

  const [displayMode, setDisplayMode] = useState(PREVIEW_MODE.value);
  const [isPublishedMode, setIsPublishedMode] = useState(false);

  useEffect(() => {
    if (location.pathname === '/huge') {
      setDisplayMode(PUBLISHED_MODE.value);
      setIsPublishedMode(true);
    } else {
      setDisplayMode(PREVIEW_MODE.value);
      setIsPublishedMode(false);
    }
  }, [location, PREVIEW_MODE.value, PUBLISHED_MODE.value]);

  return [displayMode, isPublishedMode];
}

export { useDisplayMode };
