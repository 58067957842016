import { h } from 'preact';
import styles from './Squares.scss';
import { cx } from 'utils/cx';
import { useDisplayModeContext } from 'hook/DisplayModeContext';

const Squares = () => {
  const { displayMode } = useDisplayModeContext();

  const squaresClasses = cx(styles, [
    'squares',
    `squares--${displayMode}-mode`,
  ]);

  return (
    <div className={squaresClasses}>
      <div className={styles.squares__item1}>
        <div className={styles.squares__box1}>
          <hr className={styles.squares__line1} />
          <hr className={styles.squares__line1a} />
        </div>
      </div>
      <div className={styles.squares__item2}>
        <div className={styles.squares__box2}>
          <hr className={styles.squares__line2} />
        </div>
      </div>
      <div className={styles.squares__item3}>
        <div className={styles.squares__box3}>
          <hr className={styles.squares__line3} />
        </div>
      </div>
    </div>
  );
};

export { Squares };
