import { limit, query, where } from 'firebase/firestore';

/**
 * Creates a Firestore query to retrieve the OMS offer data for a specific vehicle.
 * @param {Object} options - The query options.
 * @param {Object} options.collection - The Firestore collection to query.
 * @param {string} options.divisionCode - The division code for filtering.
 * @param {string} options.modelyear - The model year for filtering.
 * @param {string} options.dma - The DMA for filtering.
 * @param {string} options.rzip - The rzip (Representative Zip) for filtering.
 * @returns {Object} - The constructed Firestore query.
 */
const getOmsQuery = ({ collection, divisionCode, modelyear, dma, rzip }) => {
  return query(
    collection,
    limit(1), // Force limit to 1, filter should only find 1 match though
    where('divisionCode', '==', divisionCode),
    where('year', 'array-contains', modelyear),
    where('dma', '==', dma),
    where('rzip', '==', rzip)
  );
};

/**
 * Retrieves the OMS offer based on query data and the data retrieved from Firestore.
 * If there is no matching offer, it returns the default offer data.
 * @param {Object} options - The options for getting the OMS offer.
 * @param {Object} options.data - The Firestore data.
 * @param {string} options.shortModelCode - The short model code for filtering the offer.
 * @param {string} options.rzip - The rzip to be passed as additional data
 * @param {boolean} options.isActiveMY - Indicates if the model year is active.
 * @returns {Object} - The OMS offer data.
 */
const getOmsOffer = ({
  data,
  shortModelCode,
  modelyear,
  rzip,
  isActiveMY,
  stateCode,
}) => {
  const [_timestamp, allOffers] = data.docs
    .map((doc) => {
      const { _timestamp, offers } = doc.data();
      return [_timestamp, Object.values(offers)];
    })
    .flat();

  const matchingOffer = allOffers.find(
    (doc) =>
      doc.shortModelCode === shortModelCode &&
      doc.modelyear === modelyear &&
      doc.state === stateCode
  );

  const additionalData = { zip: rzip, _timestamp, isActiveMY };

  if (matchingOffer) {
    return { ...matchingOffer, ...additionalData };
  }
};

export { getOmsQuery, getOmsOffer };
