/**
 * This function is meant to get the URLs params
 * @param {object[]}
 * @returns {object[]}
 */
import constants from '../../constants';
const {
  parameters: {
    brand: BRAND_PARAM,
    model: MODEL_PARAM,
    size: SIZE_PARAM,
    modelYear: MODEL_YEAR_PARAM,
    zipCodes: ZIP_CODES_PARAM,
  },
} = constants;

function setParams({ brand, model, size, modelYear, zipCodes }) {
  const searchParams = new URLSearchParams();
  brand && searchParams.set(BRAND_PARAM, brand);
  model && searchParams.set(MODEL_PARAM, model);
  size && searchParams.set(SIZE_PARAM, size);
  modelYear && searchParams.set(MODEL_YEAR_PARAM, modelYear);
  zipCodes && searchParams.set(ZIP_CODES_PARAM, zipCodes);
  const stingSearchParams = searchParams.toString();

  // it won't refresh the page
  stingSearchParams &&
    window.history.pushState({}, '', `?${searchParams.toString()}`);
}

export { setParams };
