import { h } from 'preact';
import { Route, Routes } from 'react-router-dom';

import { NotFoundLayout } from '../NotFoundLayout';
import { Layout } from '../Layout';

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout className={'preview-mode'} />} />
      <Route path="/huge" element={<Layout className={'published-mode'} />} />
      <Route path="/*" element={<NotFoundLayout />} />
    </Routes>
  );
};

export { Router };
