import { h } from 'preact';
import { cx } from '../../utils/cx';

import { useDisplayModeContext } from '../../hook/DisplayModeContext';

import styles from './Button.scss';

const Button = ({ handlerSubmit, labelText, id, ariaLabel }) => {
  const { displayMode } = useDisplayModeContext();
  const buttonClasses = cx(styles, ['button', `button--${displayMode}-mode`]);

  return (
    <button
      id={id}
      className={buttonClasses}
      onClick={handlerSubmit}
      aria-label={ariaLabel}
      tabindex="0"
      data-test="submit"
    >
      {labelText}
    </button>
  );
};

export { Button };
