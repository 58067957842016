import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useDataListContext } from '../../hook/DataListContext';
import { LandingPage } from './LandingPage';
import { AdsContainer } from './AdsContainer';
import { cx } from '../../utils/cx';
import styles from './MainAds.scss';

const MainAds = ({ units, offersStore, setUnits }) => {
  const { appIntroAnimation, mainAdsScrolling, sideMenu } =
    useDataListContext();
  const [hasData, setHasData] = useState(units.length > 0);
  const [, setAppIntroAnimationEnd] = appIntroAnimation;
  const [mainAdsScroll, setMainAdsScroll] = mainAdsScrolling;
  const [openSideMenu] = sideMenu;
  const mainAds = useRef();

  useEffect(() => {
    setHasData(units.length > 0);
  }, [units]);

  const onAnimationMainAdsEnd = () => {
    setAppIntroAnimationEnd(true);
  };

  useEffect(() => {
    const mainAdsRef = mainAds.current;
    const setScroll = () => setMainAdsScroll(mainAdsRef.scrollTop);
    mainAdsRef.addEventListener('scroll', setScroll);

    // Set the value of MainAds ScrollTop
    // This value is just added once to prevent performance errors
    if (mainAdsScroll < 10) mainAds.current.scrollTop = mainAdsScroll;

    return () => {
      mainAdsRef.removeEventListener('scroll', setScroll);
    };
  }, [mainAdsScroll, setMainAdsScroll]);

  const mainAdsClasses = cx(styles, [
    'main-ads',
    !hasData && 'main-ads__home',
    !openSideMenu && 'main-ads__side-menu-open',
  ]);

  return (
    <section
      className={mainAdsClasses}
      ref={mainAds}
      onAnimationEnd={onAnimationMainAdsEnd}
    >
      {hasData ? (
        <AdsContainer
          units={units}
          offersStore={offersStore}
          setUnits={setUnits}
        />
      ) : (
        <LandingPage />
      )}
    </section>
  );
};

export { MainAds };
