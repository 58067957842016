// Check if value has only digits, is equal as [0-9], some examples https://regexr.com/75n70
const testIsDigit = (value) => /^\d+$/.test(value);

// Check if value has no digits by negating the outcome of testIsDigit
const testIsNotDigit = (value) => !testIsDigit(value);

// Check if value is only one letter, could be lowercase or uppercase, some examples https://regexr.com/7qgoi
const testIsSingleLetter = (value) => /^[A-Za-z]$/.test(value);

export { testIsDigit, testIsNotDigit, testIsSingleLetter };
