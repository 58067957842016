import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import firestore from '../../firestore';

/**
 * Retrieves DMA information based on a given ZIP code.
 *
 * @param {Object} options - The options for retrieving DMA data.
 * @param {string} options.zip - The ZIP code to search for.
 * @returns {Object|null} - The DMA data, or null if not found.
 */
export const getGeolocation = async ({ zip }) => {
  try {
    const geolocation = await getDocs(
      query(
        collection(firestore, process.env.GEOLOCATION_COLLECTION_NAME),
        limit(1),
        where('zips', 'array-contains', zip)
      )
    );

    if (geolocation && geolocation.size > 0) {
      return geolocation.docs[0].data();
    }

    return null;
  } catch (err) {
    // If there's an error, return null (or handle the error appropriately)
    console.error('Error in getGeolocation():', err);
    return null;
  }
};
