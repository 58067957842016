import { h, Fragment } from 'preact';
import { useEffect } from 'preact/hooks';
import { SideMenu } from '../SideMenu';
import { AdForm } from '../AdForm';
import { MainAds } from '../MainAds';
import { useDataList } from '../../hook/useDataList';
import { useDataListContext } from '../../hook/DataListContext';

const createAdTemplates = (dataForTemplates) => {
  const { zipCodes, ...brandData } = dataForTemplates;
  return zipCodes.map((zipCode, index) => {
    return {
      ...brandData,
      zipCode,
      adCount: index,
      loadingData: true,
    };
  });
};

const MainContent = () => {
  const { dataForm, unitsData } = useDataListContext();
  const [dataFromForm] = dataForm;
  const [units, setUnits] = unitsData;
  const offersStore = useDataList(units);

  useEffect(() => {
    if (offersStore.offersData.length) {
      offersStore.setOffersData([]);
      offersStore.setDataReceived(false);
      setUnits([]);
    }

    const hasZipCodes = Object.prototype.hasOwnProperty.call(
      dataFromForm,
      'zipCodes'
    );

    // TODO: we need to improve this functionality
    hasZipCodes &&
      setTimeout(() => setUnits([...createAdTemplates(dataFromForm)]), 1);
  }, [dataFromForm]);

  return (
    <Fragment>
      <SideMenu>
        <AdForm />
      </SideMenu>
      <MainAds units={units} offersStore={offersStore} setUnits={setUnits} />
    </Fragment>
  );
};

export { MainContent };
