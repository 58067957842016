import { h } from 'preact';

const InformationIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 0.25C7.26942 0.25 5.57769 0.763179 4.13876 1.72464C2.69983 2.6861 1.57832 4.05267 0.916058 5.65152C0.253791 7.25037 0.080512 9.00971 0.418133 10.707C0.755753 12.4044 1.58911 13.9635 2.81282 15.1872C4.03653 16.4109 5.59563 17.2442 7.29296 17.5819C8.9903 17.9195 10.7496 17.7462 12.3485 17.0839C13.9473 16.4217 15.3139 15.3002 16.2754 13.8612C17.2368 12.4223 17.75 10.7306 17.75 9C17.75 6.67936 16.8281 4.45376 15.1872 2.81282C13.5462 1.17187 11.3206 0.25 9 0.25ZM9 4C9.18542 4 9.36668 4.05498 9.52085 4.158C9.67502 4.26101 9.79518 4.40743 9.86614 4.57873C9.9371 4.75004 9.95566 4.93854 9.91949 5.1204C9.88332 5.30225 9.79403 5.4693 9.66292 5.60041C9.53181 5.73152 9.36476 5.82081 9.1829 5.85699C9.00104 5.89316 8.81254 5.87459 8.64124 5.80364C8.46993 5.73268 8.32351 5.61252 8.2205 5.45835C8.11749 5.30418 8.0625 5.12292 8.0625 4.9375C8.0625 4.68886 8.16128 4.4504 8.33709 4.27459C8.51291 4.09877 8.75136 4 9 4ZM11.5 14.0781H6.5V12.6719H8.29688V9.07812H7.125V7.67188H9.70313V12.6719H11.5V14.0781Z" />
    </svg>
  );
};

export { InformationIcon };
