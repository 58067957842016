const constants = {
  title: 'End Frame Previewer',
  subTitle: 'Powered by Huge',
  homeTitle: 'Welcome to the End Frame Previewer.',
  homeText: 'Glad to see you again!',
  notFoundTitle: 'Page Not Found',
  notFoundText:
    'You will be redirected to the main page of EFP in a few seconds.',
  defaultText: 'default',
  defaultMessage: 'This offer is a default',
  fieldRequired: 'This field is required',
  formError: 'Oops, something happenned on our end.',
  versionEFP: 'V3.0',
  userGuideLinkText: 'User Guide',
  dcmScriptTag:
    '<script src="https://www.googletagservices.com/dcm/dcmads.js"></script>',
  form: {
    brand: 'Brand',
    model: 'Model',
    size: 'Size',
    modelYear: 'Model Year',
    zipCodes: 'US Zip Code',
    submitButton: 'Load Ad',
    zipCodeLabelLegend:
      'Type in 5 digit zip code. Then, press enter. Maximum of 9 zip codes.',
    zipErrorMessage: 'The value must be 5 digits.',
  },
  parameters: {
    brand: 'brand',
    model: 'model',
    size: 'size',
    modelYear: 'modelYear',
    zipCodes: 'zipCodes',
  },
  mobileBreakpoint: 780,
  showLabel: 'View all offers information',
  hideLabel: 'Hide all offers information',
  mobileShowLabel: 'Show offer details',
  copies: {
    setFilters: 'Set Filters',
    mofifyFilters: 'Modify Filters',
    searchResult: 'Search Results',
  },
  landscapeMessageText: {
    text1: 'The recommended screen orientation is ',
    text2: 'portrait.',
  },
  keyboards: {
    ARROW_DOWN: 'ArrowDown',
    ARROW_UP: 'ArrowUp',
    ENTER: 'Enter',
    ESCAPE: 'Escape',
    TAB: 'Tab',
  },
  googleAnalyticsEvents: {
    adClick: {
      tag: 'ad_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    clzipClick: {
      tag: 'clzip_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    brandClick: {
      tag: 'brand_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    hideAllInfoClick: {
      tag: 'hideallinfo_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    hideMenuClick: {
      tag: 'hidemenu_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    loadClick: {
      tag: 'load_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
      additionalReport: 'Loaded URL',
    },
    logoClick: {
      tag: 'logo_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    modelClick: {
      tag: 'model_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    myClick: {
      tag: 'my_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    offerInfoClick: {
      tag: 'offerinfo_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    showMenuClick: {
      tag: 'showmenu_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    sizeClick: {
      tag: 'size_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    viewAllInfoClick: {
      tag: 'viewallinfo_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    zipClick: {
      tag: 'zip_click',
      userAction: 'Click',
      report: 'Number Of Clicks',
    },
    zipText: {
      tag: 'zip_text',
      userAction: 'Text Entry',
      report: 'Entered Text',
    },
  },
  offerDefault: {
    id: 100,
    dataSource: 'OMS',
    offerType: 'default',
    offerTemplateName: 'default',
  },
  omscowsLabels: {
    alfa: 'OMSCOWS',
    chrysler: 'OMSCOWS_chrysler',
    dodge: 'OMSCOWS_dodge',
    fiat: 'OMSCOWS_fiat',
    jeep: 'OMSCOWS_jeep',
    ram: 'OMSCOWS_ram',
  },
  displayMode: {
    preview: {
      value: 'preview',
      displayValue: 'Preview Mode',
      indicatorValue: {
        active: 'MY Active',
        inactive: 'MY Inactive',
      },
      indicatorHoverValue: {
        active: 'Active Model Year',
        inactive: 'Non-Active Model Year',
      },
      indicatorAriaLabel: {
        active: 'This is an active model year offer.',
        inactive: 'This is an inactive model year offer.',
      },
      description: `<b>Active:</b> Active model year Offers.</br>
        <b>Inactive:</b> Non-active model year Offers.`,
      ariaDescription:
        'This mode displays active model year offers and inactive model year offers',
      information:
        "Displaying all Huge's processed offers. These offers may not be published yet.",
      userGuideLink:
        'https://docs.google.com/presentation/d/1hqV1UbRjLc4dIn_IdYv5MMAfM_RAzQKC9KXsiLFt750',
    },
    published: {
      value: 'published',
      displayValue: 'Huge Mode',
      indicatorValue: 'Published',
      indicatorHoverValue: 'Published OLA offer',
      indicatorAriaLabel: 'This is a published offer',
      description:
        'Offers currently available and displayed on published Ads for OLA.',
      ariaDescription:
        'This mode displays offers currently available and displayed on published Ads for OLA.',
      information:
        'Displaying OLA offers available according to the active Model Year.',
      userGuideLink:
        'https://docs.google.com/presentation/d/137alDEMDZhHn9G8Pr-jIcVB4KjnBgNJC2ZyTgicsrZQ',
    },
  },
  iFrameMessageType: 'UPDATE_DYNAMIC_JS',
  jbAssetsBasePath: 'https://s0.2mdn.net/creatives/assets/3884341/',
  hugeModePopUp: {
    title: 'Huge mode is about to be activated',
    text: 'You are about to enter an exclusive Huge test mode. For the best experience and features, please switch to the main EFP mode.',
    btnConfirm: 'Confirm',
    btnGoBack: 'Go back to EFP',
    checkboxText: 'Don’t show again',
  },
};

export default constants;
