import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';

import { Header } from '../Header';
import { MainLayout } from '../MainLayout';
import { MainContent } from '../MainContent';
import { PopupMessage } from '../PopupMessage';
import { HugeModePopUp } from '../HugeModePopUp';
import { StatusIcon } from '../Icons/StatusIcon';
import { MobileRotation } from '../Icons/MobileRotation';

import constants from '../../constants';

import { cx } from '../../utils/cx';
import styles from './Layout.scss';

import { useDisplayModeContext } from '../../hook/DisplayModeContext';
import { useDataListContext } from '../../hook/DataListContext';
import { useViewports } from '../../hook/useViewports';

const Layout = ({ className }) => {
  const { publishModePopUp } = useDisplayModeContext();
  const [popUpLandscapeOpen, setPopUpLandscapeOpen] = useState(false);
  // const [popUpHugeMode, setPopUpHugeMode] = useState(false);
  const [popUpShowedOnce, setPopUpShowedOnce] = useState(0);
  const { isLansdcapeMobile, viewportHeight } = useViewports();
  const { sideMenu } = useDataListContext();
  const [openSideMenu] = sideMenu;
  const {
    landscapeMessageText: { text1, text2 },
  } = constants;

  const layoutClasses = cx(styles, [
    'layout',
    openSideMenu && isLansdcapeMobile && 'layout--side-menu-open',
  ]);

  useEffect(() => {
    if (popUpShowedOnce === 0) {
      setPopUpLandscapeOpen(isLansdcapeMobile);
    }
  }, [isLansdcapeMobile, popUpShowedOnce]);

  const handleClosePopUp = () => {
    setPopUpLandscapeOpen(false);
    setPopUpShowedOnce(1);
  };

  const layouHeight =
    openSideMenu && isLansdcapeMobile ? { height: viewportHeight } : {};

  return (
    <div className={className}>
      <div className={layoutClasses} style={layouHeight}>
        <Header />
        <MainLayout>
          <MainContent />
        </MainLayout>
      </div>
      <PopupMessage
        iconImage={MobileRotation}
        openFlag={popUpLandscapeOpen}
        onClickCloseBTN={handleClosePopUp}
        width={'small'}
        iconYPosition={'center'}
        btnClosePosition={'absolute'}
      >
        {text1}
        <strong>{text2}</strong>
      </PopupMessage>
      <PopupMessage
        iconImage={StatusIcon}
        iconYPosition={'top'}
        openFlag={publishModePopUp.openPopUp}
        onClickCloseBTN={publishModePopUp.handleClosePopUp}
        width={'small'}
      >
        <HugeModePopUp handlerClick={publishModePopUp.handleClosePopUp} />
      </PopupMessage>
    </div>
  );
};

export { Layout };
