import { h } from 'preact';
import PropTypes from 'prop-types';

import { getTimeAgo } from 'utils/getTimeAgo';

import styles from './LastUpdate.scss';
import ClockIcon from '../../Icons/ClockIcon';

const LastUpdate = ({ date }) => {
  const timeAgo = getTimeAgo(date);

  return (
    <div
      className={styles['last-update']}
      ariaLabel={`Last updated: ${timeAgo}`}
    >
      <ClockIcon />
      <span>{timeAgo}</span>
    </div>
  );
};

LastUpdate.propTypes = {
  date: PropTypes.number,
};

export { LastUpdate };
