import { h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import { getOfferData } from 'utils/firestore';
import { getDynamicContent } from 'utils/getDynamicContent/';
import constants from '../../../constants';

/**
 * Function to handle received messages from the iframe
 * @param {Object} event - The event object containing the received message
 * @param {string} msgType - The type of message being received
 */
const receiveMessage = ({ data }, msgType, setLoading) => {
  const { type } = data;

  if (type === msgType) {
    // Delay the setLoading call by 1 second to ensure dynamicContent update
    setTimeout(setLoading, 1000, false);
  }
};

const PreviewAd = ({
  adSpecs,
  offersStore,
  setLoading,
  setSpecs,
  setUnits,
  setActive,
  setUpdateDate,
}) => {
  const { iFrameMessageType } = constants;
  const {
    brandValue,
    dataDivisionCode,
    dataModelCode,
    dataWidth,
    dataHeight,
    modelYearValue,
    zipCode,
  } = adSpecs;
  const { setIncomingData, setDataReceived } = offersStore;
  const [isOfferLoaded, setOfferLoaded] = useState(false);
  const iframeElement = useRef(null);

  // Function to get the template path
  const getTemplatePath = () =>
    `${process.env.TEMPLATES_BUCKET_PATH}/${dataWidth}x${dataHeight}/index.html`;

  // Effect to fetch offer data when component mounts
  useEffect(() => {
    getOfferData({
      divisionCode: dataDivisionCode,
      shortModelCode: dataModelCode,
      modelyear: modelYearValue,
      zip: zipCode,
    }).then((value) => {
      const { dataSource, isActiveMY, _timestamp } = value;
      setActive(isActiveMY);
      setSpecs((prevSpecs) => ({ ...prevSpecs, dataSource, source: value }));
      setIncomingData((prevData) => [...prevData, value]);
      if (_timestamp && _timestamp.seconds) {
        setUpdateDate(_timestamp.seconds);
      }
      setDataReceived(true);
    });
  }, []);

  // Effect to update dynamic content and units when adSpecs change
  useEffect(() => {
    setUnits((prevUnits) => {
      const newUnits = [...prevUnits];
      newUnits[adSpecs.adCount] = { ...adSpecs, loadingData: false };
      return newUnits;
    });
  }, [adSpecs]);

  useEffect(() => {
    // Change brand name for 'alfa romeo' to 'alfa'
    let brand = String(brandValue).toLowerCase();
    if (brand === 'alfa romeo') brand = 'alfa';

    const newDynamicContent = getDynamicContent(brand, adSpecs);

    if (isOfferLoaded && newDynamicContent !== null) {
      // Constructing the message to be sent to the iframe
      const message = {
        brand,
        type: iFrameMessageType,
        shortModelCode: adSpecs.dataModelCode,
        dynamicContent: newDynamicContent,
      };

      // Sending the message to the iframe
      iframeElement.current.contentWindow.postMessage(message, '*');

      window.addEventListener('message', (e) =>
        receiveMessage(e, iFrameMessageType, setLoading)
      );

      return () =>
        window.removeEventListener('message', (e) =>
          receiveMessage(e, iFrameMessageType, setLoading)
        );
    }
  }, [adSpecs, isOfferLoaded]);

  return (
    <iframe
      src={getTemplatePath()}
      width={dataWidth}
      height={dataHeight}
      ref={iframeElement}
      onLoad={() => setOfferLoaded(true)}
    />
  );
};

PreviewAd.propTypes = {
  adSpecs: PropTypes.object,
  setLoading: PropTypes.func,
  setSpecs: PropTypes.func,
  setUnits: PropTypes.func,
  offersStore: PropTypes.object,
};

export { PreviewAd };
