import { h } from 'preact';
import constants from '../../constants';
import styles from './Logo.scss';

const Logo = ({ gaEvent }) => {
  const { title, versionEFP } = constants;

  return (
    <div className={styles.logo}>
      <div className={styles.logo__img}>
        <a
          href="./"
          tabindex="0"
          title="Stellantis - End Frame Previewer"
          id={gaEvent.tag}
        >
          <img src="assets/stla-logo.png" alt="Stellantis" />
        </a>
      </div>
      <div className={styles.logo__content}>
        <div className={styles.logo__title}>
          <p className={styles['logo__title-text']}>
            {title}
            <span className={styles.logo__version}> {versionEFP}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export { Logo };
