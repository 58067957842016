import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';

import constants from '../../constants';

import { Logo } from '../Logo';

import { useViewports } from '../../hook/useViewports';
import { useFieldsContext } from '../../hook/FieldsContext';
import { useDataListContext } from '../../hook/DataListContext';
import { useGoogleAnalyticsEventsContext } from '../../hook/GoogleAnalyticsEventsContext';
import { useDisplayModeContext } from '../../hook/DisplayModeContext';

import { cx } from '../../utils/cx';
import styles from './Header.scss';

const Header = () => {
  const { isPublishedMode } = useDisplayModeContext();
  const { fieldsData } = useFieldsContext();
  const { unitsData, loadingUnits } = useDataListContext();
  const googleAnalyticsEvents = useGoogleAnalyticsEventsContext();
  const { isLansdcapeMobile } = useViewports();
  const [, setUnitsAreLoading] = loadingUnits;
  const [fields] = fieldsData;
  const [units] = unitsData;
  const loader = useRef(null);
  const {
    subTitle,
    userGuideLinkText,
    displayMode: { published, preview },
  } = constants;
  const { userGuideLink } = isPublishedMode ? published : preview;

  const createValue = (value) => {
    return value.toLowerCase().split(' ').join('-');
  };

  const loaderBrandClass =
    ' ' + styles[`header__loader--${createValue(fields.brand.display)}`];

  const brandColorBarAnimation = () => {
    gsap
      .timeline()
      .to(loader.current, {
        duration: 0.3,
        autoAlpha: 0,
        ease: 'power4.out',
      })
      .to(loader.current, {
        duration: 0.3,
        autoAlpha: 1,
        ease: 'power4.out',
      });
  };

  const progressBarAnimation = () => {
    const timeline = gsap.timeline();
    const duration = 1 + units.length * 0.1;

    if (units[0].loadingData) {
      gsap.set(loader.current, { width: 0 });

      timeline.to(
        loader.current,
        {
          duration: duration,
          width: '90%',
          ease: 'steps (10)',
        },
        'in'
      );
    }

    if (!units[0].loadingData) {
      isPublishedMode && gsap.killTweensOf(loader.current);

      timeline.to(
        loader.current,
        {
          duration: 0.01,
          width: '100%',
          ease: 'linear',
          delay: 1,
          onComplete: () => {
            setUnitsAreLoading(false);
          },
        },
        'in'
      );
    }
  };

  useEffect(() => {
    brandColorBarAnimation();
  }, [fields.brand]);

  useEffect(() => {
    if (units.length) {
      progressBarAnimation();
    }
  }, [units]);

  const headerClasses = cx(styles, [
    'header',
    isLansdcapeMobile && 'header--landscape-mobile',
  ]);

  return (
    <header className={headerClasses}>
      <div className={styles.header__items}>
        <div className={styles.header__item}>
          <Logo gaEvent={googleAnalyticsEvents.logoClick} />
          <div className={styles.header__content}>
            {isPublishedMode && (
              <div className={styles['header__published-mode']}>
                <i />
                <span>{published.displayValue}</span>
              </div>
            )}
            <div className={styles['header__user-guide']}>
              <a
                target="_blank"
                href={userGuideLink}
                className={styles.header__link}
              >
                <i />
                <span>{userGuideLinkText}</span>
              </a>
            </div>
            <div className={styles.header__subtitle}>
              <p>
                {subTitle}
                {!isPublishedMode && (
                  <Link to="/huge" reloadDocument>
                    <span className={styles['header__hide-link']} />
                  </Link>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.header__loader + loaderBrandClass} ref={loader} />
    </header>
  );
};

export { Header };
