import { h } from 'preact';
import { useDataListContext } from 'hook/DataListContext';
import { Squares } from './Squares';
import constants from '../../../constants';

import styles from './LandingPage.scss';

const LandingPage = () => {
  const { sideMenu } = useDataListContext();
  const [, setOpenSideMenu] = sideMenu;
  const {
    homeTitle,
    homeText,
    copies: { setFilters },
  } = constants;

  const handleSideMenuClick = (event) => {
    if (event.key === 'Enter' || !event.key) {
      setOpenSideMenu((currentState) => !currentState);
    }
  };

  return (
    <div className={styles['landing-page']}>
      <h3 className={styles['landing-page__title']}>{homeTitle}</h3>
      <p className={styles['landing-page__text']}>{homeText}</p>
      <div
        className={styles['set-filters-btn']}
        onClick={handleSideMenuClick}
        onKeyDown={handleSideMenuClick}
        tabindex="0"
      >
        <div className={styles['set-filters-btn__icon']} />
        <p className={styles['set-filters-btn__text']}>{setFilters}</p>
      </div>
      <Squares />
    </div>
  );
};

export { LandingPage };
