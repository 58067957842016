import { h, createContext } from 'preact';
import { useFields } from '../useFields';
import { useContext } from 'preact/hooks';

const FieldsContext = createContext();

const FieldsProvider = ({ children }) => {
  const [fields, setFields, { brand, loading }] = useFields();

  return (
    <FieldsContext.Provider
      value={{
        fieldsData: [fields, setFields],
        supportedModels: { brand, loading },
      }}
    >
      {children}
    </FieldsContext.Provider>
  );
};
const useFieldsContext = () => useContext(FieldsContext);
export { FieldsProvider, FieldsContext, useFieldsContext };
