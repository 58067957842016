import { h } from 'preact';
import { useEffect } from 'preact/hooks';

import { Ad } from '../../Ad';
import { Switch } from '../../Switch';
import { InformationBanner } from '../../InformationBanner';

import constants from '../../../constants';

import { useGoogleAnalyticsEventsContext } from 'hook/GoogleAnalyticsEventsContext';
import { useDisplayModeContext } from 'hook/DisplayModeContext';
import { useDataListContext } from 'hook/DataListContext';

import { cx } from 'utils/cx';
import styles from './AdsContainer.scss';

const AdsContainer = ({ units, offersStore, setUnits }) => {
  const { offersData, dataReceived } = offersStore;
  const { adsInfo, mainAdsScrolling } = useDataListContext();
  const { isPublishedMode } = useDisplayModeContext();
  const googleAnalyticsEvents = useGoogleAnalyticsEventsContext();

  const [showAdsInfo, setShowAdsInfo] = adsInfo;
  const [mainAdsScroll] = mainAdsScrolling;
  const {
    showLabel,
    hideLabel,
    copies: { searchResult },
  } = constants;

  useEffect(() => {
    if (units.length) {
      setShowAdsInfo(new Array(units.length).fill(false));
    }
  }, [units]);

  const showAllAdsData = () => {
    setShowAdsInfo((currentState) => {
      return new Array(currentState.length).fill(true);
    });
  };

  const hideAllAdsData = () => {
    setShowAdsInfo((currentState) => {
      return new Array(currentState.length).fill(false);
    });
  };

  useEffect(() => {
    if (dataReceived) {
      const unitsWithOffer = units.map((unit) => {
        const offerForZip = offersData.find((offerData) => {
          const { zip = [] } = offerData;
          return zip.includes(unit.zipCode);
        });

        const unitDefault = {
          ...unit,
          loadingData: false,
          dataSource: 'OMS',
        };

        if (offerForZip) {
          return {
            ...unitDefault,
            dataSource: offerForZip.dataSource,
            source: offerForZip,
          };
        }

        return unitDefault;
      });

      setUnits(unitsWithOffer);
    }
  }, [dataReceived]);

  const dataHasUnits = units.length > 1 && !units[0].loadingData;
  const isInfoDefault = ([key, data]) => {
    return !data.source || data.source.offerType === 'default';
  };
  const allUnitsAreDefault = Object.entries(units).every(isInfoDefault);

  const mainAdsHeaderClasses = cx(styles, [
    'ads-container__header',
    mainAdsScroll > 10 && 'ads-container__header-box-shadow',
  ]);

  return (
    <div className={styles['ads-container']}>
      <header
        className={mainAdsHeaderClasses}
        tabIndex="0"
        ariaLabel={searchResult}
        data-test="ads-container"
      >
        {isPublishedMode && <InformationBanner />}
        <div className={styles['ads-container__title-container']}>
          <h3 className={styles['ads-container__title']}>{searchResult}</h3>
          {!allUnitsAreDefault && dataHasUnits && (
            <Switch
              showLabel={showLabel}
              showAllAdsData={showAllAdsData}
              showGATag={googleAnalyticsEvents.viewAllInfoClick.tag}
              hideLabel={hideLabel}
              hideAllAdsData={hideAllAdsData}
              hideGATag={googleAnalyticsEvents.hideAllInfoClick.tag}
            />
          )}
        </div>
      </header>
      <div className={styles['ads-container__results']}>
        {units.map((adData, index) => {
          return (
            <Ad
              adSpecs={adData}
              key={index + units[index]}
              index={index}
              showAdInfo={showAdsInfo[index]}
              setShowAdsInfo={setShowAdsInfo}
              setUnits={setUnits}
              offersStore={offersStore}
            />
          );
        })}
      </div>
    </div>
  );
};

export { AdsContainer };
