import { h } from 'preact';
import styles from './HugeModePopUp.scss';
import { Link } from 'react-router-dom';
import constants from '../../constants';
import { useDisplayModeContext } from '../../hook/DisplayModeContext';

const Btn = ({ className, handlerClick, text, tabindex, onKeyDown }) => (
  <div
    className={styles.btn + ' ' + className}
    onClick={handlerClick}
    onKeyDown={onKeyDown}
    tabindex={tabindex}
  >
    <p>{text}</p>
  </div>
);

const HugeModePopUp = () => {
  const {
    publishModePopUp: { handleClosePopUp, checked, setChecked },
  } = useDisplayModeContext();

  const {
    hugeModePopUp: { title, text, btnConfirm, btnGoBack, checkboxText },
    keyboards: { ENTER },
  } = constants;

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleInputKeyDown = (event) => {
    const { key } = event;
    if (key === ENTER) handleCheckboxChange();
  };

  const handleBtnKeyDown = (event) => {
    const { key } = event;
    if (key === ENTER) handleClosePopUp();
  };

  return (
    <div className={styles['huge-mode__main']}>
      <div className={styles['huge-mode__modal-header']}>
        <div className={styles['huge-mode__text']}>
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
      </div>
      <div className={styles['huge-mode__modal-action']}>
        <div className={styles['huge-mode__checkbox']}>
          <input
            type="checkbox"
            id="doNotShow"
            name="doNotShow"
            checked={checked}
            onChange={handleCheckboxChange}
            onKeyDown={handleInputKeyDown}
            tabindex="0"
          />
          <label for="doNotShow">{checkboxText}</label>
        </div>
        <div className={styles['huge-mode__btns']}>
          <Link to={'/'} reloadDocument>
            <Btn text={btnGoBack} className={styles['btn--white']} />
          </Link>
          <Btn
            text={btnConfirm}
            handlerClick={handleClosePopUp}
            className={styles['btn--blue']}
            onKeyDown={handleBtnKeyDown}
            tabindex="0"
          />
        </div>
      </div>
    </div>
  );
};

export { HugeModePopUp };
