/**
 * This function is meant to get the URLs params
 * @param {object[]}
 * @returns {object[]}
 */
import constants from '../../constants';
const {
  parameters: {
    brand: BRAND_PARAM,
    model: MODEL_PARAM,
    size: SIZE_PARAM,
    modelYear: MODEL_YEAR_PARAM,
    zipCodes: ZIP_CODES_PARAM,
  },
} = constants;

function getParams(location) {
  const searchParams = new URLSearchParams(location.search);

  return {
    brand: searchParams.get(BRAND_PARAM) || '',
    model: searchParams.get(MODEL_PARAM) || '',
    size: searchParams.get(SIZE_PARAM) || '',
    modelYear: searchParams.get(MODEL_YEAR_PARAM) || '',
    zipCodes: searchParams.get(ZIP_CODES_PARAM) || '',
  };
}

export { getParams };
