import { h } from 'preact';

import styles from './MainLayout.scss';
import { cx } from '../../utils/cx';
import { useViewports } from '../../hook/useViewports';

const MainLayout = ({ children }) => {
  const { mainContentViewHeight, isLansdcapeMobile } = useViewports();

  const heightMainLayout = !isLansdcapeMobile
    ? { height: mainContentViewHeight }
    : {};

  const mainLayoutClasses = cx(styles, [
    'main__layout',
    isLansdcapeMobile && 'main__layout--landscape-mobile',
  ]);

  return (
    <main className={mainLayoutClasses} style={heightMainLayout}>
      {children}
    </main>
  );
};

export { MainLayout };
