// This prevent the ReferenceError: window is not defined
let isIPhone, isAndroid, isChrome, isSafari;

if (typeof window !== 'undefined') {
  const { userAgent } = navigator;

  isIPhone = userAgent.match(/(iphone)/i);
  isAndroid = userAgent.match(/(android)/i);
  isChrome = userAgent.match(/chrome|chromium|crios/i);
  isSafari = userAgent.match(/AppleWebKit/i);
}

const isSafariIPhone = isSafari && isIPhone;
const isChromeIPhone = isChrome && isIPhone;
const isChromeAndroid = isChrome && isAndroid;
const isMobile = isIPhone || isAndroid;

export { isSafariIPhone, isChromeIPhone, isChromeAndroid, isMobile };
