import { limit, query, where } from 'firebase/firestore';

/**
 * Creates a Firestore query to retrieve the MSRP data for a specific vehicle.
 * @param {string} options.collection - Firestore collection.
 * @param {string} options.queryData.divisionCode - The division code of the vehicle.
 * @param {string} options.queryData.shortModelCode - The short model code of the vehicle.
 * @param {number} options.queryData.modelyear - The model year of the vehicle.
 * @returns {Query} - The Firestore query to retrieve the MSRP data.
 */
const getMsrpQuery = ({
  collection,
  divisionCode,
  shortModelCode,
  modelyear,
}) => {
  return query(
    collection,
    limit(1), // Force limit to 1, filter should only find 1 match though
    where('divisionCode', '==', divisionCode),
    where('shortModelCode', '==', shortModelCode),
    where('modelyear', '==', modelyear)
  );
};

/**
 * Retrieves the MSRP offer data from the Firestore query result.
 * If the query result is empty, it returns the default offer data.
 * @param {Object} options - The options for getting the MSRP offer.
 * @param {QuerySnapshot} options.data - Firestore query snapshot.
 * @param {string} options.bc - The bccode for additional data.
 * @param {string} options.dma - The dma for additional data.
 * @param {string} options.rzip - The rzip for additional data.
 * @param {boolean} options.isActiveMY - Indicates if the model year is active.
 * @returns {Object} - The MSRP offer data.
 */
const getMsrpOffer = ({ data, bc, dma, rzip, isActiveMY }) => {
  const [docSnapshot] = data.docs;
  const offer = docSnapshot.data();
  const additionalData = { bccode: bc, zip: rzip, dma, isActiveMY };

  if (offer) {
    return { ...offer, ...additionalData };
  }
};

export { getMsrpQuery, getMsrpOffer };
