/**
 * Formats time unit string based on the count of time units.
 *
 * @param {number} time - The count of time units.
 * @param {string} unit - The name of the time unit.
 * @returns {string} The formatted time unit string.
 */
const formatTimeAgoString = (time, unit) => {
  const singularForm = time === 1 ? unit : `${unit}s`; // Use singular form if time is 1
  return `${time} ${singularForm} ago`;
};

/**
 * Returns formatted date from timestamp.
 *
 * @param {number} timestamp - Timestamp in seconds.
 */
const getDateFromTimestamp = (timestamp) => {
  const timestampInMilliseconds = timestamp * 1000;
  const updateDate = new Date(timestampInMilliseconds);

  return updateDate.toLocaleDateString(undefined, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

/**
 * Converts a timestamp into a human-readable display format.
 *
 * @param {number} timestamp - The Unix timestamp to be formatted.
 * @returns {string} The formatted display string.
 */
const getTimeAgo = (timestamp) => {
  if (
    timestamp === null ||
    timestamp === undefined ||
    !Number.isInteger(timestamp) ||
    timestamp < 0
  ) {
    return 'Invalid Date';
  }

  const now = Math.floor(Date.now() / 1000); // Current timestamp in seconds
  const secondsAgo = now - timestamp;

  const MINUTE = 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;
  const WEEK = DAY * 7;
  const MONTH = WEEK * 4;

  // Compare with predefined time thresholds
  if (secondsAgo < HOUR) {
    const minutes = Math.floor(secondsAgo / MINUTE);
    return formatTimeAgoString(minutes, 'minute');
  } else if (secondsAgo < DAY) {
    const hours = Math.floor(secondsAgo / HOUR);
    return formatTimeAgoString(hours, 'hour');
  } else if (secondsAgo < WEEK) {
    const days = Math.floor(secondsAgo / DAY);
    return formatTimeAgoString(days, 'day');
  } else if (secondsAgo < MONTH) {
    const weeks = Math.floor(secondsAgo / WEEK);
    return formatTimeAgoString(weeks, 'week');
  }

  return getDateFromTimestamp(timestamp);
};

export { getTimeAgo, getDateFromTimestamp };
