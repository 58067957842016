import { useState, useEffect } from 'preact/hooks';

function useDataList(units) {
  const [incomingData, setIncomingData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [dataReceived, setDataReceived] = useState(false);

  // This event will be fired when the Window object receives a message from s0.2mdn (server used by certain adware to distribute advertisements)
  // We need to filter those messages to know the offer source (OMS or COWS)
  useEffect(() => {
    const receiveMessage = (event) => {
      const { data } = event;

      if (!Array.isArray(data)) {
        return;
      }

      const [
        dataSource,
        offerTemplateName,
        offerType,
        startDate,
        dma,
        bccode,
        model,
        shortModelCode,
        modelyear,
        expiryDate,
        zip,
      ] = data;

      const offerData = {
        dataSource,
        offerTemplateName,
        offerType,
        startDate,
        dma,
        bccode,
        model,
        shortModelCode,
        modelyear,
        expiryDate,
        zip,
      };

      setIncomingData((prevState) => [...prevState, offerData]);
    };
    window.addEventListener('message', receiveMessage, false);

    // on unmount
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  useEffect(() => {
    if (units.length && units.length === incomingData.length) {
      setDataReceived(true);
      setOffersData((prevState) => [...prevState, ...incomingData]);
      setIncomingData([]);
    }
  }, [units, incomingData]);

  return {
    offersData,
    setOffersData,
    dataReceived,
    setDataReceived,
    setIncomingData,
  };
}

export { useDataList };
