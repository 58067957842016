import { h, createContext } from 'preact';
import { useState, useContext } from 'preact/hooks';

const DataListContext = createContext();

const DataListProvider = ({ children }) => {
  const [dataFromForm, setDataFromForm] = useState({});
  const [units, setUnits] = useState([]);
  const [showAdsInfo, setShowAdsInfo] = useState([]);
  const [unitsAreLoading, setUnitsAreLoading] = useState();
  const [appIntroAnimationEnd, setAppIntroAnimationEnd] = useState(false);
  const [openSideMenu, setOpenSideMenu] = useState(true);
  const [mainAdsScroll, setMainAdsScroll] = useState(0);

  return (
    <DataListContext.Provider
      value={{
        dataForm: [dataFromForm, setDataFromForm],
        unitsData: [units, setUnits],
        adsInfo: [showAdsInfo, setShowAdsInfo],
        loadingUnits: [unitsAreLoading, setUnitsAreLoading],
        appIntroAnimation: [appIntroAnimationEnd, setAppIntroAnimationEnd],
        sideMenu: [openSideMenu, setOpenSideMenu],
        mainAdsScrolling: [mainAdsScroll, setMainAdsScroll],
      }}
    >
      {children}
    </DataListContext.Provider>
  );
};
const useDataListContext = () => useContext(DataListContext);

export { DataListProvider, DataListContext, useDataListContext };
