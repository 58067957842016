import { h } from 'preact';
import PropTypes from 'prop-types';
import { useDisplayModeContext } from '../../hook/DisplayModeContext';
import styles from './DropdownItems.scss';
import { cx } from 'utils/cx';

const DropdownItems = ({ items, handleSelectItem }) => {
  const { displayMode } = useDisplayModeContext();

  const dropDownItemsClasses = cx(styles, [
    'dropdown__item',
    `dropdown__item--${displayMode}-mode`,
  ]);

  return items.map(({ display, value }, index) => (
    <div
      tabindex="0"
      className={dropDownItemsClasses}
      key={index}
      onClick={(event) => handleSelectItem(event, display, value)}
      onKeyDown={(event) => handleSelectItem(event, display, value)}
    >
      {display}
    </div>
  ));
};

DropdownItems.propTypes = {
  items: PropTypes.array.isRequired,
  handleSelectItem: PropTypes.func.isRequired,
};

export { DropdownItems };
