import { getDoc, doc } from 'firebase/firestore';
import firestore from '../../firestore';

export const getActiveModelYear = async (queryData) => {
  const { divisionCode, shortModelCode, bc, stateCode } = queryData;
  try {
    const activeModelYear = await getDoc(
      doc(
        firestore,
        process.env.MODEL_YEAR_COLLECTION_NAME,
        `${divisionCode}_${bc}_${stateCode}`
      )
    );

    if (activeModelYear.exists()) {
      const { vehicles } = activeModelYear.data();
      const vehicle = vehicles.find((v) => v.model_code === shortModelCode);
      if (vehicle && vehicle.year) {
        return vehicle.year;
      }
    }

    return null;
  } catch (err) {
    // If there's an error, return default offer
    console.error(err);
    return null;
  }
};
