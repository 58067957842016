import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useNavigate } from 'react-router-dom';

import { Header } from '../Header';
import constants from '../../constants';

import { Squares } from '../MainAds/LandingPage/Squares';
import { MainLayout } from '../MainLayout';
import styles from './NotFoundLayout.scss';

const NotFoundLayout = () => {
  const navigate = useNavigate();

  const { notFoundTitle, notFoundText } = constants;

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 4000);
  }, []);

  return (
    <div>
      <Header />
      <MainLayout>
        <div className={styles['not-found-page']}>
          <h3 className={styles['not-found-page__title']}>{notFoundTitle}</h3>
          <p className={styles['not-found-page__text']}>{notFoundText}</p>
          <Squares />
        </div>
      </MainLayout>
    </div>
  );
};

export { NotFoundLayout };
