import { limit, query, where } from 'firebase/firestore';

/**
 * Creates a Firestore query to retrieve the COWS offer data for a specific vehicle.
 * @param {Object} options - The query options.
 * @param {Object} options.collection - The Firestore collection to query.
 * @param {string} options.divisionCode - The division code for filtering.
 * @param {string} options.dma - The DMA for filtering.
 * @param {string} options.modelyear - The model year for filtering.
 * @param {string} options.shortModelCode - The short model code.
 * @param {string} options.rzip - The rzip (Representative Zip) for filtering.
 * @returns {Object} - The constructed Firestore query.
 */
const getCowsQuery = ({
  collection,
  divisionCode,
  stateCode,
  modelyear,
  shortModelCode,
  rzip,
}) => {
  return query(
    collection,
    limit(1), // Force limit to 1, filter should only find 1 match though
    where('divisionCode', '==', divisionCode),
    where('shortModelCode', '==', shortModelCode),
    where('modelyear', '==', modelyear),
    where('@state', '==', stateCode),
    where('rzip', '==', rzip)
  );
};

/**
 * Retrieves the COWS offer based on query data and the data retrieved from Firestore.
 * If there is no matching offer, it returns the default offer data.
 * @param {Object} options - The options for getting the COWS offer.
 * @param {Object} options.data - The Firestore data.
 * @param {string} options.dma - The dma for additional data.
 * @param {string} options.rzip - The rzip for additional data.
 * @param {boolean} options.isActiveMY - Indicates if the model year is active.
 * @returns {Object} - The COWS offer data.
 */
const getCowsOffer = ({ data, dma, rzip, isActiveMY }) => {
  const [docSnapshot] = data.docs;
  const offer = docSnapshot.data();
  const additionalData = {
    dma,
    isActiveMY,
    zip: rzip,
    vehname: `${offer['@vehname']}.png`,
    offerTemplateName: offer.offer,
  };

  if (offer) {
    return { ...offer, ...additionalData };
  }
};

export { getCowsQuery, getCowsOffer };
