import { h } from 'preact';

const CopyToClipboardIcon = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3863 6.35693V13.8566"
        stroke="black"
        stroke-width="0.8"
        stroke-linejoin="round"
      />
      <path
        d="M6.38644 12.2246V16H16.386V12.2246"
        stroke="black"
        stroke-width="0.8"
        stroke-linejoin="round"
      />
      <path
        d="M9.24346 8.14258C10.0803 7.30577 10.5494 6.83661 11.3862 5.99981L13.529 8.14258"
        stroke="black"
        stroke-width="0.8"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CopyToClipboardIcon;
