import { useState, useEffect, useCallback } from 'preact/hooks';
import { getDocs, collection } from 'firebase/firestore';
import { brand as initialBrandState } from '../../data';
import firestore from 'utils/firestore';

function useSupportedModels() {
  let cachedBrands;
  const modelCollectionName = process.env.MODEL_COLLECTION_NAME;
  const [brands, setBrands] = useState(initialBrandState);
  const [loading, setLoading] = useState(true);

  if (typeof window !== 'undefined') {
    cachedBrands = window.localStorage.getItem('brands');
  }

  const getModelCodes = useCallback(async (updateCache = false) => {
    // Fetch data from firestore collection in the server side
    try {
      const coll = collection(firestore, modelCollectionName);
      const newModelCodes = await getDocs(coll);

      if (newModelCodes.empty) {
        throw new Error(
          `${modelCollectionName} Collection returned no models - ${
            cachedBrands ? 'Using Cache fallback' : 'No cache available'
          }`
        );
      }

      const newBrands = [...initialBrandState];

      newModelCodes.forEach((doc) => {
        const docId = doc.id;
        const { models } = doc.data();
        const newModels = models
          .filter(({ supported }) => supported)
          .map(({ code, name }) => ({
            dataModelCode: code,
            value: code,
            display: name,
          }))
          .sort((a, b) => {
            const nameA = a.display.toUpperCase();
            const nameB = b.display.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });

        const brandIdx = initialBrandState.findIndex(
          ({ dataDivisionCode }) => dataDivisionCode === docId
        );
        newBrands[brandIdx].model = newModels;
      });

      setBrands(() => {
        if (updateCache)
          localStorage.setItem('brands', JSON.stringify(newBrands));
        return newBrands;
      });
      setLoading(false);

      return newBrands;
    } catch (err) {
      if (cachedBrands) setBrands(JSON.parse(cachedBrands)); // use cache fallback
      setLoading(false);
      throw new Error(`useSupportedModels hook => ${err}`);
    }
  }, []);

  useEffect(() => {
    // add brands to cache
    if (!cachedBrands) {
      getModelCodes(true);
      return;
    }

    // compare if the cache is outdated
    const compareCached = async () => {
      const updatedBrands = await getModelCodes();

      if (JSON.stringify(updatedBrands) !== cachedBrands) {
        console.warn('Cache of Brand Models was outdated - updating');
        getModelCodes(true);
      }
    };

    compareCached();
  }, [getModelCodes, cachedBrands]);

  return { loading, brands };
}

export { useSupportedModels };
