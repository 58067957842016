import { h } from 'preact';
import { useState } from 'preact/hooks';
import { SuccessMessage } from '../SuccessMessage';
import CopyToClipboardIcon from '../Icons/CopyToClipboard.js';
import { getParams } from '../../utils/getParams';
import styles from './ShareAd.scss';

const ShareAd = ({ zipCode }) => {
  const [sharingURL, setSharingURL] = useState(false);

  const generateShareableLink = async () => {
    const { brand, model, size, modelYear } = getParams(window.location);

    const newURL = `${window.origin}${window.location.pathname}?brand=${brand}&model=${model}&size=${size}&modelYear=${modelYear}&zipCodes=${zipCode}`;
    await navigator.clipboard.writeText(newURL);
  };

  const handleClickShareButton = () => {
    setSharingURL(true);
    generateShareableLink().then(() => {
      setTimeout(() => setSharingURL(false), 1500);
    });
  };

  return (
    <div className={styles.share}>
      <div
        className={styles.share__btn}
        onClick={handleClickShareButton}
        tabindex="0"
      >
        <span className={styles['share__btn-text']}>Share</span>
        <CopyToClipboardIcon />
      </div>
      {sharingURL && (
        <SuccessMessage successText={'Copied'} successFLag={sharingURL} />
      )}
    </div>
  );
};

export { ShareAd };
