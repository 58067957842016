import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';

import { DropdownItems } from '../DropdownItems';
import { FieldError } from '../FieldError';

import constants from '../../constants';

import { testIsDigit, testIsSingleLetter } from '../../utils/regex';
import { cx } from '../../utils/cx';

import styles from './Dropdown.scss';

const {
  keyboards: { ARROW_DOWN, ARROW_UP, ENTER, ESCAPE, TAB },
} = constants;

const pickAlphabeticOption = (HTMLOptions, key) => {
  const capitalizedKey = key.toUpperCase();
  return HTMLOptions.find(
    (child) => child.innerHTML[0].toUpperCase() === capitalizedKey
  );
};

const pickNumericOption = (HTMLOptions, key) => {
  const numberKey = Number(key);
  return HTMLOptions.find((child) => Number(child.innerHTML[0]) === numberKey);
};

const getDigitOption = (HTMLOptions, key) =>
  testIsDigit(key) && pickNumericOption(HTMLOptions, key);

const getAlphabeticOption = (HTMLOptions, key) =>
  testIsSingleLetter(key) && pickAlphabeticOption(HTMLOptions, key);

const getNextOption = (options, selectedOption, key) => {
  const currentIdx = options.indexOf(selectedOption);
  const [firstOption] = options;
  const nextOption = options[currentIdx + 1];
  return [ARROW_DOWN, TAB].includes(key) && (nextOption || firstOption);
};

const getPrevOption = (options, selectedOption, key) => {
  const lastIdx = options.length - 1;
  const currentIdx = options.indexOf(selectedOption);
  const lastOption = options[lastIdx];
  const prevOption = options[currentIdx - 1];
  return key === ARROW_UP && (prevOption || lastOption);
};

const Dropdown = ({
  id,
  items,
  parentType,
  handlerClick,
  gaEvent,
  hasErrors,
  setFieldsError,
  optionSelected,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [optionFocused, setOptionFocused] = useState(false);
  const [firstClick, setFirstClick] = useState(0);
  const menuRef = useRef(null);
  const btnRef = useRef(null);

  useEffect(() => {
    const handlerClickOutside = (e) => {
      if (e.target !== btnRef.current) {
        setDropdownOpen(false);
        setOptionFocused(false);
      }
    };

    document.addEventListener('click', handlerClickOutside);

    return () => document.removeEventListener('click', handlerClickOutside);
  }, []);

  useEffect(() => {
    gsap.to(menuRef.current, {
      duration: 0.3,
      autoAlpha: dropdownOpen ? 1 : 0,
      y: dropdownOpen ? 0 : 5,
      ease: 'power4.out',
    });
  }, [dropdownOpen]);

  useEffect(() => {
    const isVisited = !dropdownOpen && firstClick;

    isVisited &&
      setFieldsError((prevFieldError) => ({
        ...prevFieldError,
        [parentType]: !optionSelected.value,
      }));
  }, [
    dropdownOpen,
    firstClick,
    setFieldsError,
    parentType,
    optionSelected.value,
  ]);

  const handleClick = () => {
    setFirstClick(1);
    setDropdownOpen((currentState) => !currentState);
  };

  const handleSelectItem = (event, display, value) => {
    const isEnterPressed = event.key === 'Enter';
    const isClicked = !event.key;
    if (isEnterPressed || isClicked) {
      setDropdownOpen((currentState) => !currentState);
      handlerClick({ display, parentType, value });
    }
  };

  const handleKeyDown = (event) => {
    const { key } = event;

    const shouldOpen = (key) => [ARROW_DOWN, ARROW_UP, ENTER].includes(key);

    const openMenu = shouldOpen(key) && !dropdownOpen;

    if (key === ESCAPE) {
      setDropdownOpen(false);
      btnRef.current.blur();
    }
    if (openMenu) {
      setFirstClick(1);
      setDropdownOpen(true);
    }
    // Prevent normal event when 'Tab' is pressed and the dropdown is open
    key === TAB && dropdownOpen && event.preventDefault();

    const focusableOptions = [...menuRef.current.children];
    const firstOption = getNextOption(focusableOptions, optionFocused, key);
    const lastOption = getPrevOption(focusableOptions, optionFocused, key);
    const alphabeticOption = getAlphabeticOption(focusableOptions, key);
    const digitOption = getDigitOption(focusableOptions, key);

    const nextFocusableOption =
      firstOption || lastOption || alphabeticOption || digitOption;

    if (nextFocusableOption) {
      setOptionFocused(nextFocusableOption);
      setTimeout(() => {
        nextFocusableOption.focus();
      }, 100);
    }
  };

  const handleFocusChange = (event) => {
    const { key } = event;

    if (key === ESCAPE) {
      setDropdownOpen(false);
      menuRef.current.blur();
    }
    // Prevent normal event when 'Tab' is pressed and the dropdown is open
    key === TAB && dropdownOpen && event.preventDefault();

    const focusableOptions = [...menuRef.current.children];
    const nextOption = getNextOption(focusableOptions, optionFocused, key);
    const prevOption = getPrevOption(focusableOptions, optionFocused, key);
    const alphabeticOption = getAlphabeticOption(focusableOptions, key);
    const digitOption = getDigitOption(focusableOptions, key);

    const nextFocusableOption =
      nextOption || prevOption || alphabeticOption || digitOption;

    if (nextFocusableOption) {
      setOptionFocused(nextFocusableOption);
      nextFocusableOption.focus();
    }
  };

  const dropdownClasses = cx(styles, [
    'dropdown__btn',
    hasErrors && 'dropdown__btn--error',
  ]);

  return (
    <div className={styles.dropdown} id={id} name={parentType}>
      <div
        ref={btnRef}
        className={dropdownClasses}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabindex="0"
        id={gaEvent}
        ariaLabel={`Select ${parentType} dropdown. Required`}
        data-test={parentType}
      >
        {optionSelected.display}
      </div>
      {hasErrors && <FieldError />}
      <div
        ref={menuRef}
        className={styles.dropdown__items}
        onKeyDown={handleFocusChange}
      >
        <DropdownItems items={items} handleSelectItem={handleSelectItem} />
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  parentType: PropTypes.string.isRequired,
  handlerClick: PropTypes.func.isRequired,
  event: PropTypes.object,
};

export { Dropdown };
