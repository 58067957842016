import { Fragment, h } from 'preact';
import { cx } from '../../utils/cx';
import styles from './PopupMessage.scss';
import constants from '../../constants';

const PopupMessage = ({
  children,
  iconImage,
  openFlag,
  onClickCloseBTN,
  iconYPosition,
  width,
  btnClosePosition = 'static',
}) => {
  const {
    keyboards: { ENTER },
  } = constants;

  const popUpClasses = cx(styles, [
    'popup',
    openFlag && 'popup--open',
    `popup--size-${width}`,
  ]);

  const popUpContainerClasses = cx(styles, [
    'popup__container',
    width && `popup__container--size-${width}`,
  ]);

  const popUpIconClasses = cx(styles, [
    'popup__icon',
    iconYPosition && `popup__icon--${iconYPosition}`,
  ]);

  const popUpCloseButtonClasses = cx(styles, [
    'popup__btn-close',
    btnClosePosition && `popup__btn-close--${btnClosePosition}`,
  ]);
  const closeIcon = "url('assets/close.png')";

  const handleKeyDown = (event) => {
    const { key } = event;
    if (key === ENTER) onClickCloseBTN();
  };

  return (
    <Fragment>
      {openFlag && (
        <div className={popUpClasses}>
          <div className={popUpContainerClasses}>
            {iconImage && (
              <span
                className={popUpIconClasses}
                style={{ backgroundImage: `url('${iconImage}')` }}
              />
            )}
            <div className={styles.popup__text}>{children}</div>
            <div
              className={popUpCloseButtonClasses}
              style={{ backgroundImage: closeIcon }}
              onClick={onClickCloseBTN}
              tabindex="0"
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export { PopupMessage };
