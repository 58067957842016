/**
 * This function is meant to be used to get an array of 5 years that includes the current
 *  and 4 before the current.
 * @returns {object[]} - Contains 5 model-years based on the current model-year.
 */

const getDynamicAvailableYears = () => {
  const currentYear = new Date().getFullYear();
  const nextYearIndex = 1;
  const yearsAvailableRange = 5;

  const years = Array.from({ length: yearsAvailableRange }).map((_, index) => {
    const year = (currentYear + nextYearIndex - index).toString();
    return { display: year, value: year };
  });

  return years;
};

export { getDynamicAvailableYears };
