import { h, createContext } from 'preact';
import constants from '../../constants';
import { useContext } from 'preact/hooks';
import { useDisplayModeContext } from '../../hook/DisplayModeContext';

const GoogleAnalyticsEventsContext = createContext();

const GoogleAnalyticsEventsProvider = ({ children }) => {
  const { isPublishedMode } = useDisplayModeContext();

  const { googleAnalyticsEvents } = constants;

  const updateGoogleAnalyticsEvents = {};

  Object.entries(googleAnalyticsEvents).forEach(([eventName, event]) => {
    updateGoogleAnalyticsEvents[eventName] = {
      ...event,
      tag: isPublishedMode ? `huge_${event.tag}` : event.tag,
    };
  });

  return (
    <GoogleAnalyticsEventsContext.Provider value={updateGoogleAnalyticsEvents}>
      {children}
    </GoogleAnalyticsEventsContext.Provider>
  );
};

const useGoogleAnalyticsEventsContext = () =>
  useContext(GoogleAnalyticsEventsContext);

export {
  GoogleAnalyticsEventsProvider,
  GoogleAnalyticsEventsContext,
  useGoogleAnalyticsEventsContext,
};
